import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchClients,
  fetchClientsSucceeded,
  fetchClient,
  fetchClientSucceeded,
  saveClient,
  saveClientSucceeded,
  fetchUsers,
  fetchUsersSucceeded,
  fetchUser,
  fetchUserSucceeded,
  saveUser,
  saveUserSucceeded,
  fetchClientSettings,
  fetchClientSettingsSucceeded,
  fetchClientSetting,
  fetchClientSettingSucceeded,
  saveClientSetting,
  saveClientSettingSucceeded,
  fetchUserSettings,
  fetchUserSettingsSucceeded,
  fetchUserSetting,
  fetchUserSettingSucceeded,
  saveUserSetting,
  saveUserSettingSucceeded,
  fetchMessages,
  fetchMessagesSucceeded,
  fetchMessage,
  fetchMessageSucceeded,
  fetchMessageCount,
  setMessageCount,
  fetchLeadShare,
  fetchLeadShareSucceeded,
  saveLeadShare,
  saveLeadShareSucceeded,
  deleteUser,
  deleteUserSucceeded,
  fetchUsersAdmin,
  fetchUsersAdminSucceeded,
  fetchUserAdmin,
  fetchUserAdminSucceeded,
  saveUserAdmin,
  saveUserAdminSucceeded,
  deleteUserAdmin,
  deleteUserAdminSucceeded,
  searchDomain,
  searchDomainSucceeded,
  fetchDomainPrice,
  fetchDomainPriceSucceeded,
  saveDomain,
  saveDomainSucceeded,
  fetchMailchimp,
  fetchMailchimpSucceeded,
  fetchLinkedIn,
  fetchLinkedInSucceeded,
  fetchFacebook,
  fetchFacebookSucceeded,
  IPut,
} from "../reducers/clientsSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchMailchimp(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/clients/getMailChimp",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchMailchimpSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchLinkedIn(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/clients/getLinkedIn",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchLinkedInSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchFacebook(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/clients/getFacebook",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchFacebookSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchClients(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clients/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchClientsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchClient(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/clients/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchClientSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveClient(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clients/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveClientSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchUsers(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/users/getClientUsers", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchUsersSucceeded(response.data));
  } catch (e) {
    // handle errors
  }
}

function* runFetchUser(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/users/getClientUser",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchUserSucceeded(response.data));
  } catch (e) {
    // handle errors
  }
}

function* runSaveUser(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/users/addClientUser", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchUsers(data);
    yield put(saveUserSucceeded(response));
    data.payload.name = "used_user_count";
    yield runFetchClientSetting(data);
  } catch (e) {
    // handle errors
  }
}

function* runDeleteUser(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/users/remove", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchUsers(data);
    yield put(deleteUserSucceeded(response));
    data.payload.name = "used_user_count";
    yield runFetchClientSetting(data);
  } catch (e) {
    // handle errors
  }
}

function* runFetchClientSettings(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clientSettings/list", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchClientSettingsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchClientSetting(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clientSettings/get", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchClientSettingSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveClientSetting(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clientSettings/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchClientSettings(data);
    yield put(saveClientSettingSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchUserSettings(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/userSettings/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchUserSettingsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchUserSetting(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/userSettings/get",
        { name: data.payload.name },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchUserSettingSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveUserSetting(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/userSettings/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveUserSettingSucceeded(response));
    yield runFetchUserSettings(data);
  } catch (e) {
    // handle errors
  }
}

function* runFetchMessages(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/messages/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchMessagesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchMessage(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/messages/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchMessageSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchMessageCount(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/marketingEmails/sendingStats",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(
      setMessageCount({
        sent_messages: response.send,
        to_be_sent: response.timing,
      })
    );
  } catch (e) {
    // handle errors
  }
}

function* runFetchLeadShare(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/ClientLeadShareSettings/get",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchLeadShareSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveLeadShare(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/ClientLeadShareSettings/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveLeadShareSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchUsersAdmin(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/users/getClientUsersAdmin",
        { client_id: data.payload.client_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchUsersAdminSucceeded(response.data));
  } catch (e) {
    // handle errors
  }
}

function* runFetchUserAdmin(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/users/getClientUserAdmin",
        { id: data.payload.id, client_id: data.payload.client_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchUserAdminSucceeded(response.data));
  } catch (e) {
    // handle errors
  }
}

function* runSaveUserAdmin(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/users/addClientUser", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchUsersAdmin(data);
    yield put(saveUserAdminSucceeded(response));
    data.payload.name = "used_user_count";
    yield runFetchClientSetting(data);
  } catch (e) {
    // handle errors
  }
}

function* runDeleteUserAdmin(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/users/remove", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchUsersAdmin(data);
    yield put(deleteUserAdminSucceeded(response));
    data.payload.name = "used_user_count";
    yield runFetchClientSetting(data);
  } catch (e) {
    // handle errors
  }
}

function* runSearchDomain(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clients/searchDomain", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(searchDomainSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchDomainPrice(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clients/checkPrice", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchDomainPriceSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveDomain(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/clients/saveDomain", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveDomainSucceeded(response));
    yield runFetchClient(data);
  } catch (e) {
    // handle errors
  }
}

function* clientsSaga() {
  yield takeEvery(fetchClients.type, runFetchClients);
  yield takeEvery(fetchClient.type, runFetchClient);
  yield takeEvery(saveClient.type, runSaveClient);

  yield takeEvery(fetchUsersAdmin.type, runFetchUsersAdmin);
  yield takeEvery(fetchUserAdmin.type, runFetchUserAdmin);
  yield takeEvery(saveUserAdmin.type, runSaveUserAdmin);
  yield takeEvery(deleteUserAdmin.type, runDeleteUserAdmin);

  yield takeEvery(fetchUsers.type, runFetchUsers);
  yield takeEvery(fetchUser.type, runFetchUser);
  yield takeEvery(saveUser.type, runSaveUser);
  yield takeEvery(deleteUser.type, runDeleteUser);

  yield takeEvery(fetchClientSettings.type, runFetchClientSettings);
  yield takeEvery(fetchClientSetting.type, runFetchClientSetting);
  yield takeEvery(saveClientSetting.type, runSaveClientSetting);

  yield takeEvery(fetchUserSettings.type, runFetchUserSettings);
  yield takeEvery(fetchUserSetting.type, runFetchUserSetting);
  yield takeEvery(saveUserSetting.type, runSaveUserSetting);

  yield takeEvery(fetchMessages.type, runFetchMessages);
  yield takeEvery(fetchMessage.type, runFetchMessage);

  yield takeEvery(fetchMessageCount.type, runFetchMessageCount);

  yield takeEvery(fetchLeadShare.type, runFetchLeadShare);
  yield takeEvery(saveLeadShare.type, runSaveLeadShare);

  yield takeEvery(searchDomain.type, runSearchDomain);

  yield takeEvery(fetchDomainPrice.type, runFetchDomainPrice);

  yield takeEvery(saveDomain.type, runSaveDomain);

  yield takeEvery(fetchMailchimp.type, runFetchMailchimp);

  yield takeEvery(fetchLinkedIn.type, runFetchLinkedIn);

  yield takeEvery(fetchFacebook.type, runFetchFacebook);
}

export default clientsSaga;
