import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAccount {
  id: string;
  name: string;
}

export interface ICampaign {
  id: string;
  name: string;
  status: string;
}

export interface IAd {
  id: string;
  creative_id: string;
  name: string;
  object_story_spec: any;
  preview: any;
}

export interface ISetting {
  id: string;
  adaccount_id: string;
  page_id: string;
}

export interface LinkedinState {
  accounts: Array<IAccount> | undefined;
  account: IAccount | undefined;
  setting: ISetting | undefined;
  campaigngroup: any;
  campaigngroups: any;
  campaigns: Array<ICampaign> | undefined;
  campaign: any;
  ad: IAd | undefined;
  ads: any;
  preview: any;
  audiences: any;
  share: any;
  adtemplates: any;
  companies: any;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  type?: number;
  campaign_id?: string;
  share_id?: string;
  starttime?: number;
  endtime?: number;
  status?: string;
}

const initialState: LinkedinState = {
  accounts: undefined,
  account: undefined,
  setting: undefined,
  campaigngroup: undefined,
  campaigngroups: undefined,
  campaigns: undefined,
  campaign: undefined,
  ad: undefined,
  ads: undefined,
  preview: undefined,
  audiences: undefined,
  share: undefined,
  adtemplates: undefined,
  companies: undefined,
};

const linkedinSlice = createSlice({
  name: "linkedin",
  initialState,
  reducers: {
    fetchAccounts: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchAccountsSucceeded: (
      state: LinkedinState,
      action: PayloadAction<Array<IAccount>>
    ) => {
      state.accounts = action.payload;
    },
    fetchAccount: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchAccountSucceeded: (
      state: LinkedinState,
      action: PayloadAction<IAccount>
    ) => {
      state.account = action.payload;
    },
    saveAccount: (state: LinkedinState, action: PayloadAction<any>) => {},
    saveAccountSucceeded: (
      state: LinkedinState,
      action: PayloadAction<IAccount>
    ) => {
      state.account = undefined;
    },
    clearAccount: (state: LinkedinState) => {
      state.account = undefined;
    },

    fetchSetting: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchSettingSucceeded: (
      state: LinkedinState,
      action: PayloadAction<ISetting>
    ) => {
      state.setting = action.payload;
    },

    fetchCampaignGroups: (
      state: LinkedinState,
      action: PayloadAction<IPut>
    ) => {},
    fetchCampaignGroupsSucceeded: (
      state: LinkedinState,
      action: PayloadAction<Array<any>>
    ) => {
      state.campaigngroups = action.payload;
    },

    fetchCampaigns: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchCampaignsSucceeded: (
      state: LinkedinState,
      action: PayloadAction<Array<ICampaign>>
    ) => {
      state.campaigns = action.payload;
    },
    saveCampaign: (state: LinkedinState, action: PayloadAction<any>) => {},
    saveCampaignSucceeded: (
      state: LinkedinState,
      action: PayloadAction<ICampaign>
    ) => {
      state.campaign = action.payload;
    },

    fetchAd: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchAds: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchAdsSucceeded: (state: LinkedinState, action: PayloadAction<any>) => {
      state.ads = action.payload;
    },

    saveAd: (state: LinkedinState, action: PayloadAction<any>) => {},
    saveAdSucceeded: (state: LinkedinState, action: PayloadAction<any>) => {
      state.ad = action.payload;
    },
    fetchAdPreview: (state: LinkedinState, action: PayloadAction<any>) => {},
    setAdPreview: (state: LinkedinState, action: PayloadAction<any>) => {
      if (state.ad) state.ad.preview = action.payload;
    },
    fetchPreview: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchPreviewSucceeded: (
      state: LinkedinState,
      action: PayloadAction<any>
    ) => {
      state.preview = action.payload;
    },

    saveCampaignAdsetAd: (
      state: LinkedinState,
      action: PayloadAction<any>
    ) => {},
    saveCampaignAdsetAdSucceeded: (
      state: LinkedinState,
      action: PayloadAction<any>
    ) => {
      state.campaign = action.payload.campaign_id;
      //state.adset = action.payload.adset_id;
      state.ad = action.payload.ad_id;
      window.location.href = "/some/linkedin";
    },
    fetchAudiences: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    setAudiences: (state: LinkedinState, action: PayloadAction<any>) => {
      state.audiences = action.payload;
    },

    fetchShare: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchShareSucceeded: (state: LinkedinState, action: PayloadAction<any>) => {
      state.share = action.payload;
    },
    updateCampaign: (state: LinkedinState, action: PayloadAction<any>) => {},
    updateCampaignSucceeded: (
      state: LinkedinState,
      action: PayloadAction<any>
    ) => {
      state.share = undefined;
    },
    setShare: (state: LinkedinState, action: PayloadAction<any>) => {
      state.ad = action.payload;
    },
    updateCampaignStatus: (
      state: LinkedinState,
      action: PayloadAction<any>
    ) => {},
    updateCampaignStatusSucceeded: (
      state: LinkedinState,
      action: PayloadAction<any>
    ) => {},
    fetchAdTemplates: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    fetchAdTemplatesSucceeded: (
      state: LinkedinState,
      action: PayloadAction<Array<any>>
    ) => {
      state.adtemplates = action.payload;
    },
    listCompanies: (state: LinkedinState, action: PayloadAction<IPut>) => {},
    listCompaniesSucceeded: (
      state: LinkedinState,
      action: PayloadAction<Array<any>>
    ) => {
      state.companies = action.payload;
    },
  },
});

export const {
  fetchAccounts,
  fetchAccountsSucceeded,
  fetchAccount,
  fetchAccountSucceeded,
  saveAccount,
  saveAccountSucceeded,
  clearAccount,
  fetchSetting,
  fetchSettingSucceeded,
  fetchCampaigns,
  fetchCampaignsSucceeded,
  saveCampaign,
  saveCampaignSucceeded,
  saveAd,
  saveAdSucceeded,
  fetchPreview,
  fetchPreviewSucceeded,
  fetchAds,
  fetchAd,
  fetchAdPreview,
  setAdPreview,
  fetchAdsSucceeded,
  saveCampaignAdsetAd,
  saveCampaignAdsetAdSucceeded,
  fetchCampaignGroups,
  fetchCampaignGroupsSucceeded,
  fetchAudiences,
  setAudiences,
  fetchShare,
  fetchShareSucceeded,
  updateCampaign,
  updateCampaignSucceeded,
  setShare,
  updateCampaignStatus,
  updateCampaignStatusSucceeded,
  fetchAdTemplates,
  fetchAdTemplatesSucceeded,
  listCompanies,
  listCompaniesSucceeded,
} = linkedinSlice.actions;

export default linkedinSlice;
