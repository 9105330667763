import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import "./tailwind-generated.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { I18nProvider } from "@lingui/react";
import { Spin, Layout } from "antd";

import store, { persistor } from "./store";
//import { store, persistor, sagaMiddleware } from './store';

import { AppState } from "./reducers/rootReducer";

import { MainMenu, CreateRoutes, MenuItem } from "./pages/PageRouter";

import PrivacyPopup from "./ui-components/PrivacyPopup";

// import MainMenu from "./components/Menu/MainMenu";

import { PersistGate } from "redux-persist/integration/react";

const I18nWrapper: React.FC = () => {
  const locale = "fi"; //useSelector((state: AppState) => state.locale);
  const catalogs = {
    [locale]: require(`./locales/${locale}/messages.js`).default,
  };

  return (
    <I18nProvider language={locale} catalogs={catalogs}>
      <App />
    </I18nProvider>
  );
};

/*
const PageRoute = (item: MenuItem, key: string) => {
  function lazyLoadComponent(url: string) {
    //
    // Lazy loading main pages
    return lazy(() => import("./pages/" + url));
  }

  let subRoutes = null;
  let route = null;
  if (item.sub && item.sub.length > 0) {
    subRoutes = item.sub.map((subItem: MenuItem, subkey) => {
      return PageRoute(subItem, key + "-" + subkey); //(subkey + 1) / 100)
    });
  }

  if (item.path && item.component) {
    route = (
      <Route
        key={key}
        exact
        path={item.path}
        title={item.title}
        component={lazyLoadComponent(item.component)}
      />
    );
  }

  return (
    <React.Fragment>
      {route ? route : ""}
      {subRoutes ? subRoutes : ""}
    </React.Fragment>
  );
};*/

const App: React.FC = () => {
  //const Home = lazy(() => import("./pages/Home/index"))
  /*
  MenuItems.map((item: MenuItem, key) => {
				if ( item.id ) {
					const checker = serviceList.filter( ( service ) => service.id === item.id );
					if ( checker.length === 0 ) {
						return '';
					}
        }
  

  function lazyLoadComponent(url?: string) {
    //
    // Lazy loading main pages
    return lazy(() => import("./pages/" + url));
  }*/

  //const pRoutes = PageRoutes;
  return (
    <Router>
      <Suspense
        fallback={
          <div className="text-center pt-12">
            <Spin></Spin>
          </div>
        }
      >
        <MainMenu />
        <Switch>
          <CreateRoutes />
        </Switch>
        {/*<PrivacyPopup />*/}
      </Suspense>
    </Router>
  );
};
/*
  <PersistGate loading={null} persistor={persistor}>
      <I18nWrapper />
    </PersistGate>
*/
//<Provider store={{...store, ...tempStore}}>
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18nWrapper />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
