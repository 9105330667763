import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchTemplateLists,
  fetchTemplateListsSucceeded,
  fetchTemplateList,
  fetchTemplateListSucceeded,
  saveTemplateList,
  saveTemplateListSucceeded,
  sendTestMail,
  fetchEmailLists,
  fetchEmailListsSucceeded,
  fetchEmailList,
  fetchEmailListSucceeded,
  saveEmailList,
  saveEmailListSucceeded,
  fetchEmailInfo,
  fetchEmailInfoSucceeded,
  fetchDays,
  fetchDaysSucceeded,
  fetchReports,
  fetchReportsSucceeded,
  fetchExcel,
  deleteTemplate,
  deleteTemplateSucceeded,
  copyTemplate,
  copyTemplateSucceeded,
  IPut,
} from "../reducers/marketingSlice";
import { backend_address } from "../config/axios";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

/* Templates */
function* runFetchTemplateLists(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingEmailTemplates/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchTemplateListsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchTemplateList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingEmailTemplates/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchTemplateListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveTemplateList(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingEmailTemplates/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    //yield runFetchTemplateLists(data);
    yield put(saveTemplateListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runDeleteTemplate(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingEmailTemplates/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteTemplateSucceeded(response));
    yield runFetchTemplateLists(data);
  } catch (e) {
    // handle errors
  }
}

function* runCopyTemplate(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingEmailTemplates/makeCopy",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(copyTemplateSucceeded(response));
    yield runFetchTemplateLists(data);
  } catch (e) {
    // handle errors
  }
}

function* runSendTestMail(data: any) {
  try {
    const token = data.payload.template.token;
    const template = data.payload.template;
    const response = yield call(() =>
      backend.post("/MarketingEmailTemplates/add", template, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    try {
      const sendResponse = yield call(() =>
        backend.post(
          "/MarketingEmailTemplates/sendTestMail",
          {
            id: response.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      );
      window.open(sendResponse);
    } catch (e) {
      // handle errors
    }

    //yield runFetchTemplateLists(data);
    //yield put(saveTemplateListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

/* Email */
function* runFetchEmailLists(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingEmails/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchEmailListsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchEmailList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingEmails/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchEmailListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveEmailList(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingEmails/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchEmailLists(data);
    yield put(saveEmailListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runfetchEmailInfo(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingEmails/info",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchEmailInfoSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runfetchDays(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Days/list",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchDaysSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runfetchReports(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingEmails/reports", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchReportsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runfetchExcel(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingEmails/makeReportExcel", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    window.open(
      "" +
        backend_address +
        "/MarketingEmails/downloadExcel/" +
        response.filename +
        "/" +
        response.sha +
        ""
    );
  } catch (e) {
    // handle errors
  }
}

function* marketingSaga() {
  yield takeEvery(fetchTemplateLists.type, runFetchTemplateLists);
  yield takeEvery(fetchTemplateList.type, runFetchTemplateList);
  yield takeEvery(saveTemplateList.type, runSaveTemplateList);
  yield takeEvery(sendTestMail.type, runSendTestMail);

  yield takeEvery(deleteTemplate.type, runDeleteTemplate);
  yield takeEvery(copyTemplate.type, runCopyTemplate);

  yield takeEvery(fetchEmailLists.type, runFetchEmailLists);
  yield takeEvery(fetchEmailList.type, runFetchEmailList);
  yield takeEvery(saveEmailList.type, runSaveEmailList);

  yield takeEvery(fetchEmailInfo.type, runfetchEmailInfo);

  yield takeEvery(fetchDays.type, runfetchDays);

  yield takeEvery(fetchReports.type, runfetchReports);

  yield takeEvery(fetchExcel.type, runfetchExcel);
}

export default marketingSaga;
