import localeSlice from "./localeSlice";
import tasksSlice from "./tasksSlice";
import userData from "./userData";
import clientsSlice from "./clientsSlice";
import b2bSlice from "./b2bSlice";
import contactsSlice from "./contactsSlice";
import marketingSlice from "./marketingSlice";
import customersSlice from "./customersSlice";
import leadsSlice from "./leadsSlice";
import filesSlice from "./filesSlice";
import publicSlice from "./publicSlice";
import facebookSlice from "./facebookSlice";
import linkedinSlice from "./linkedinSlice";

import { PayloadAction } from "@reduxjs/toolkit";

import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
//import * as localforage from "localforage";

export type PayloadActionWithResolve<T> = {
  onResolve?: (data?: any) => void;
} & PayloadAction<T>;

const rootReducer = persistCombineReducers(
  {
    key: "root",
    storage: storage,
    //whitelist
    blacklist: [
      "tasks",
      "clients",
      "b2b",
      "contacts",
      "marketing",
      "customers",
      "leads",
      "files",
      "facebook",
      "linkedin",
    ],
  },
  {
    user: userData.reducer,
    tasks: tasksSlice.reducer,
    locale: localeSlice.reducer,
    clients: clientsSlice.reducer,
    b2b: b2bSlice.reducer,
    contacts: contactsSlice.reducer,
    marketing: marketingSlice.reducer,
    customers: customersSlice.reducer,
    leads: leadsSlice.reducer,
    files: filesSlice.reducer,
    public: publicSlice.reducer,
    facebook: facebookSlice.reducer,
    linkedin: linkedinSlice.reducer,
  }
);

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
