import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IImage {
  id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  filename: string;
  origfilename: string;
  type: string;
  url: string;
}

export interface FilesState {
  images: Array<IImage> | "loading" | undefined;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  customer_id?: number;
}

const initialState: FilesState = {
  images: undefined,
};

const FilesSlice = createSlice({
  name: "files",
  initialState,
  reducers: {
    fetchImages: (state: FilesState, action: PayloadAction<IPut>) => {},
    setImages: (
      state: FilesState,
      action: PayloadAction<IImage[] | "loading" | undefined>
    ) => {
      state.images = action.payload;
    },
  },
});

export const { fetchImages, setImages } = FilesSlice.actions;

export default FilesSlice;
