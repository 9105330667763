import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { message } from "antd";

export interface ITemplate {
  id: number;
  name: string;
  created_at: number;
  modified_at: number;
  status_id: number;
  client_id: number;
  user_id: number;
  url?: string;
  title?: string;
}

interface IEmailinfo {
  id: number;
  status_id: number;
  email_count: number;
  send_count: number;
  read_count: number;
  click_count: number;
  bounce_count: number;
  unsubscribe_count: number;
  read: any;
  click: any;
  bounce: any;
  unsubscribe: any;
  parts: any;
  url: string;
}

export interface ISending {
  date: string | undefined;
  time: string | undefined;
  count: number | undefined;
}

export interface IEmail {
  id: number;
  name: string;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  from_address: string;
  to_address: string;
  reply_address: string;
  from_name: string;
  subject: string;
  message: string;
  status_id: number;
  send_at: number;
  marketing_list_id: number[];
  marketing_email_id: number;
  lists: number[];
  sending_time: number;
  sending_times: ISending[];
  send_type: number;
  time: number;
  parts_number: number;
  limits?: number;
  url?: string;
  limit_date?: number;
}

interface IDays {
  id: number;
  name: string;
}

interface IReport {
  company: string;
  name: string;
  email: string;
  phone: string;
  time: number;
}

interface ITestMail {
  template: ITemplate;
  emails: string[];
}

export interface MarketingState {
  templates: Array<ITemplate> | undefined;
  template: ITemplate | undefined;

  emails: Array<IEmail> | undefined;
  email: IEmail | undefined;

  info: IEmailinfo | undefined;

  days: Array<IDays> | undefined;

  reports: Array<IReport> | undefined;
}

interface IUserMessage {
  msg: string;
  type?: "success" | "error" | "warning" | "loading";
  duration?: number;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  type?: number;
  starttime?: number;
  endtime?: number;
}

const initialState: MarketingState = {
  templates: undefined,
  template: undefined,

  emails: undefined,
  email: undefined,

  info: undefined,

  days: undefined,

  reports: undefined,
};

const msg = message;

function messageClear() {
  setTimeout(function () {
    msg.destroy();
  }, 500);
}

function setUserMessage(iMsg: IUserMessage) {
  //IUserMessage
  msg.destroy();
  switch (iMsg.type) {
    case "success": {
      msg.success(iMsg.msg, 3);
      break;
    }
    case "error": {
      msg.error(iMsg.msg, 3);
      break;
    }
    case "warning": {
      msg.warning(iMsg.msg, 3);
      break;
    }
    case "loading": {
      msg.loading(iMsg.msg, 0);
      break;
    }
    default: {
      msg.info(iMsg.msg, 3);
      break;
    }
  }
}

const marketingSlice = createSlice({
  name: "marketing",
  initialState,
  reducers: {
    setMessage: (
      state: MarketingState,
      action: PayloadAction<IUserMessage>
    ) => {
      setUserMessage(action.payload);
    },
    fetchTemplateLists: (
      state: MarketingState,
      action: PayloadAction<IPut>
    ) => {},
    fetchTemplateListsSucceeded: (
      state: MarketingState,
      action: PayloadAction<Array<ITemplate>>
    ) => {
      state.templates = action.payload;
    },
    fetchTemplateList: (
      state: MarketingState,
      action: PayloadAction<IPut>
    ) => {},
    fetchTemplateListSucceeded: (
      state: MarketingState,
      action: PayloadAction<ITemplate>
    ) => {
      state.template = action.payload;
    },
    saveTemplateList: (
      state: MarketingState,
      action: PayloadAction<ITemplate>
    ) => {},
    saveTemplateListSucceeded: (
      state: MarketingState,
      action: PayloadAction<ITemplate>
    ) => {
      state.template = undefined;
    },
    clearTemplate: (state: MarketingState) => {
      state.template = undefined;
    },
    sendTestMail: (state: MarketingState, action: PayloadAction<ITestMail>) => {
      //msg.loading("Haetaan tietoja...", 0);
      // Testi viesti lähetetty!
    },

    deleteTemplate: (state: MarketingState, action: PayloadAction<IPut>) => {},
    deleteTemplateSucceeded: (
      state: MarketingState,
      action: PayloadAction<ITemplate>
    ) => {
      state.template = undefined;
    },

    copyTemplate: (state: MarketingState, action: PayloadAction<IPut>) => {},
    copyTemplateSucceeded: (
      state: MarketingState,
      action: PayloadAction<ITemplate>
    ) => {
      state.template = undefined;
    },

    fetchEmailLists: (state: MarketingState, action: PayloadAction<IPut>) => {},
    fetchEmailListsSucceeded: (
      state: MarketingState,
      action: PayloadAction<Array<IEmail>>
    ) => {
      state.info = undefined;
      state.emails = action.payload;
    },
    fetchEmailList: (state: MarketingState, action: PayloadAction<IPut>) => {},
    clearEmailList: (state: MarketingState) => {
      state.email = undefined;
    },
    fetchEmailListSucceeded: (
      state: MarketingState,
      action: PayloadAction<IEmail>
    ) => {
      state.email = action.payload;
    },
    saveEmailList: (state: MarketingState, action: PayloadAction<IEmail>) => {},
    saveEmailListSucceeded: (
      state: MarketingState,
      action: PayloadAction<IEmail>
    ) => {
      state.email = undefined;
    },

    fetchEmailInfo: (state: MarketingState, action: PayloadAction<IPut>) => {},

    fetchEmailInfoSucceeded: (
      state: MarketingState,
      action: PayloadAction<IEmailinfo>
    ) => {
      state.info = action.payload;
    },

    fetchDays: (state: MarketingState, action: PayloadAction<IPut>) => {},
    fetchDaysSucceeded: (
      state: MarketingState,
      action: PayloadAction<Array<IDays>>
    ) => {
      state.days = action.payload;
    },

    fetchReports: (state: MarketingState, action: PayloadAction<IPut>) => {},
    fetchReportsSucceeded: (
      state: MarketingState,
      action: PayloadAction<Array<IReport>>
    ) => {
      state.reports = action.payload;
    },
    fetchExcel: (state: MarketingState, action: PayloadAction<IPut>) => {},
  },
});

export const {
  fetchTemplateLists,
  fetchTemplateListsSucceeded,
  fetchTemplateList,
  fetchTemplateListSucceeded,
  saveTemplateList,
  saveTemplateListSucceeded,
  sendTestMail,
  clearTemplate,
  copyTemplate,
  copyTemplateSucceeded,

  fetchEmailLists,
  fetchEmailListsSucceeded,
  fetchEmailList,
  clearEmailList,
  fetchEmailListSucceeded,
  saveEmailList,
  saveEmailListSucceeded,

  fetchEmailInfo,
  fetchEmailInfoSucceeded,

  fetchDays,
  fetchDaysSucceeded,

  fetchReports,
  fetchReportsSucceeded,
  fetchExcel,

  deleteTemplate,
  deleteTemplateSucceeded,

  setMessage,
} = marketingSlice.actions;

export default marketingSlice;
