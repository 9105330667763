import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchCustomers,
  fetchCustomersSucceeded,
  fetchCustomer,
  fetchCustomerSucceeded,
  saveCustomer,
  saveCustomerSucceeded,
  fetchActions,
  fetchActionsSucceeded,
  fetchAction,
  fetchActionSucceeded,
  saveAction,
  saveActionSucceeded,
  fetchAttachments,
  fetchAttachmentsSucceeded,
  fetchAttachment,
  fetchAttachmentSucceeded,
  saveAttachment,
  saveAttachmentSucceeded,
  deleteAttachment,
  deleteAttachmentSucceeded,
  fetchContacts,
  fetchContactsSucceeded,
  fetchContact,
  fetchContactSucceeded,
  saveContact,
  saveContactSucceeded,
  deleteContact,
  deleteContactSucceeded,
  fetchDeals,
  fetchDealsSucceeded,
  fetchDeal,
  fetchDealSucceeded,
  saveDeal,
  saveDealSucceeded,
  deleteDeal,
  deleteDealSucceeded,
  fetchTodos,
  fetchTodosSucceeded,
  fetchTodo,
  fetchTodoSucceeded,
  saveTodo,
  saveTodoSucceeded,
  deleteTodo,
  deleteTodoSucceeded,
  fetchNotes,
  fetchNotesSucceeded,
  fetchNote,
  fetchNoteSucceeded,
  saveNote,
  saveNoteSucceeded,
  deleteNote,
  deleteNoteSucceeded,
  fetchProducts,
  fetchProductsSucceeded,
  fetchProduct,
  fetchProductSucceeded,
  saveProduct,
  saveProductSucceeded,
  fetchPipelines,
  //fetchPipelinesSucceeded,
  //fetchPipelinesAdmin,
  fetchPipelinesList,
  setPipelinesList,
  savePipelinesList,
  savePipelineList,
  setPipelineList,
  //fetchPipelinesAdminSucceeded,
  fetchPipeline,
  fetchPipelineSucceeded,
  savePipeline,
  savePipelineSucceeded,
  fetchActionTypes,
  fetchActionTypesSucceeded,
  fetchTodoTypes,
  fetchTodoTypesSucceeded,
  fetchTodoStatus,
  fetchTodoStatusSucceeded,
  fetchB2BCompanyData,
  fetchB2BCompanyDataSucceeded,
  fetchFrontPage,
  fetchFrontPageSucceeded,
  deleteCustomer,
  deleteCustomerSucceeded,
  saveVatnumberClient,
  saveVatnumberClientSucceeded,
  IPut,
} from "../reducers/customersSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchCustomers(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customers/list", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchCustomersSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchCustomer(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customers/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchCustomerSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveCustomer(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customers/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    console.log(response.id);
    yield put(saveCustomerSucceeded(response));
  } catch (e) {}
}

function* runDeleteCustomer(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Customers/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteCustomerSucceeded(response));
  } catch (e) {}
}

function* runFetchActions(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerActions/list",
        { customer_id: data.payload.customer_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchActionsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchAction(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerActions/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchActionSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveAction(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customerActions/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveActionSucceeded(response));
    yield runFetchActions(data);
  } catch (e) {}
}

function* runFetchAttachments(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerAttachments/list",
        { customer_id: data.payload.customer_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAttachmentsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchAttachment(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerAttachments/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAttachmentSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveAttachment(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customerAttachments/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveAttachmentSucceeded(response));
    yield runFetchAttachments(data);
  } catch (e) {}
}

function* runDeleteAttachment(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerAttachments/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteAttachmentSucceeded(response));
    data.payload.id = data.payload.customer_id;
    yield runFetchAttachments(data);
  } catch (e) {}
}

function* runFetchContacts(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerContacts/list",
        { customer_id: data.payload.customer_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchContactsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchContact(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerContacts/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchContactSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveContact(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerContacts/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveContactSucceeded(response));
    yield runFetchContacts(data);
  } catch (e) {}
}

function* runDeleteContact(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerContacts/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteContactSucceeded(response));
    yield runFetchContacts(data);
  } catch (e) {}
}

function* runFetchDeals(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerDeals/list",
        { customer_id: data.payload.customer_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchDealsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchDeal(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerDeals/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchDealSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveDeal(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customerDeals/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveDealSucceeded(response));
    yield runFetchDeals(data);
  } catch (e) {}
}

function* runDeleteDeal(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerDeals/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteDealSucceeded(response));
    yield runFetchDeals(data);
  } catch (e) {}
}

function* runFetchTodos(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerTodos/list",
        { customer_id: data.payload.customer_id, filter: data.payload.filter },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchTodosSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchTodo(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/customerTodos/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield runFetchContacts({
      payload: { customer_id: response.customer_id, token: data.payload.token },
    });
    yield put(fetchTodoSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveTodo(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customerTodos/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveTodoSucceeded(response));
    if (data.payload.notcustomer == 1) {
      data.payload.customer_id = 0;
    }
    yield runFetchTodos(data);
    yield runFetchFrontPage(data);
  } catch (e) {}
}

function* runDeleteTodo(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerTodos/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteTodoSucceeded(response));
    yield runFetchTodos(data);
  } catch (e) {}
}

function* runFetchNotes(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerNotes/list",
        { customer_id: data.payload.customer_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchNotesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchNote(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerNotes/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchNoteSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveNote(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerNotes/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveNoteSucceeded(response));
    yield runFetchNotes(data);
  } catch (e) {}
}

function* runDeleteNote(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerNotes/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(deleteNoteSucceeded(response));
    yield runFetchNotes(data);
  } catch (e) {}
}

function* runFetchProducts(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerProducts/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchProductsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchProduct(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerProducts/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchProductSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSaveProduct(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerProducts/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveProductSucceeded(response));
    yield runFetchProducts(data);
  } catch (e) {}
}

/*
function* runFetchPipelinesAdmin(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerPipelines/listAdmin", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchPipelinesAdminSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchPipelines(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerPipelines/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchPipelinesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}*/

/*
/customerPipelines/listClass
Ei parametreja
 
/customerPipelines/getClass
id = ID numero
 
/customerPipelines/addClass
name = luokan nimi
 
/customerPipelines/deleteClass
id = ID numero
 
Lisäksi muutoksia
 
/customerPipelines/add
Lisätty parametri "customer_pipeline_class_id" joka on luokan ID numero johon vaihe liitetään
 
/customerPipelines/delete
id = Id numero
 
/customerPipelines/list ja /customerPipelines/listAdmin
id = luokan id numero. Tällöin tulostaa vain ko luokkaan kuuluvat vaiheet
*/

function* runFetchPipelinesList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerPipelines/listClass",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    //console.log('FetchPipelinesList',response);
    yield put(setPipelinesList(response));
  } catch (e) {
    // handle errors
  }
}

function* runSavePipelinesList(data: any) {
  try {
    const pipelines: any[] = [];
    data.payload.pipelines.map((list: any) => {
      list.customer_pipelines.map((pipeline: any, key: number) => {
        pipelines.push({
          id: pipeline.id,
          order_num: key,
          customer_pipeline_class_id: pipeline.customer_pipeline_class_id,
        });
      });
    });
    //const response =
    yield call(() =>
      backend.post(
        "/CustomerPipelines/saveOrder",
        {
          pipelines: pipelines,
        },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
  } catch (e) {
    // handle errors
  }
}

function* runSavePipelineList(data: any) {
  try {
    //const response =
    yield call(() =>
      backend.post("/CustomerPipelines/addClass", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(setPipelineList(undefined));
    yield runFetchPipelinesList(data);
    //console.log('response: ',response);
  } catch (e) {
    // handle errors
  }
}
/* ------ */

function* runFetchPipeline(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/CustomerPipelines/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchPipelineSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runSavePipeline(data: any) {
  try {
    console.log("runSavePipeline", data.payload);
    const response = yield call(() =>
      backend.post("/CustomerPipelines/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    console.log("runSavePipeline response", response);
    yield put(savePipelineSucceeded(response));
    yield runFetchPipelinesList(data);
  } catch (e) {}
}

function* runFetchActionTypes(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/ActionTypes/list",
        { type_id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchActionTypesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchTodoTypes(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerTodos/listTypes", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchTodoTypesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchTodoStatus(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/CustomerTodos/listStatus", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchTodoStatusSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BCompanyData(data: any) {
  if (data.payload.filter) {
    try {
      const response = yield call(() =>
        backend.post(
          "/B2B/getCompanyData",
          { vatnumber: data.payload.filter },
          {
            headers: {
              Authorization: `Bearer ${data.payload.token}`,
            },
          }
        )
      );
      yield put(fetchB2BCompanyDataSucceeded(response));
    } catch (e) {
      // handle errors
    }
  } else {
    try {
      const response = yield call(() =>
        backend.post(
          "/B2B/getCompanyData",
          { id: data.payload.id },
          {
            headers: {
              Authorization: `Bearer ${data.payload.token}`,
            },
          }
        )
      );
      yield put(fetchB2BCompanyDataSucceeded(response));
    } catch (e) {
      // handle errors
    }
  }
}

function* runFetchFrontPage(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/frontpage/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchFrontPageSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveVatnumberClient(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/customers/saveVatNumberCustomers", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveVatnumberClientSucceeded(response));
  } catch (e) {}
}

function* customersSaga() {
  yield takeEvery(fetchCustomers.type, runFetchCustomers);
  yield takeEvery(fetchCustomer.type, runFetchCustomer);
  yield takeEvery(saveCustomer.type, runSaveCustomer);
  yield takeEvery(deleteCustomer.type, runDeleteCustomer);

  yield takeEvery(fetchActions.type, runFetchActions);
  yield takeEvery(fetchAction.type, runFetchAction);
  yield takeEvery(saveAction.type, runSaveAction);

  yield takeEvery(fetchAttachments.type, runFetchAttachments);
  yield takeEvery(fetchAttachment.type, runFetchAttachment);
  yield takeEvery(saveAttachment.type, runSaveAttachment);
  yield takeEvery(deleteAttachment.type, runDeleteAttachment);

  yield takeEvery(fetchContacts.type, runFetchContacts);
  yield takeEvery(fetchContact.type, runFetchContact);
  yield takeEvery(saveContact.type, runSaveContact);
  yield takeEvery(deleteContact.type, runDeleteContact);

  yield takeEvery(fetchDeals.type, runFetchDeals);
  yield takeEvery(fetchDeal.type, runFetchDeal);
  yield takeEvery(saveDeal.type, runSaveDeal);
  yield takeEvery(deleteDeal.type, runDeleteDeal);

  yield takeEvery(fetchTodos.type, runFetchTodos);
  yield takeEvery(fetchTodo.type, runFetchTodo);
  yield takeEvery(saveTodo.type, runSaveTodo);
  yield takeEvery(deleteTodo.type, runDeleteTodo);

  yield takeEvery(fetchNotes.type, runFetchNotes);
  yield takeEvery(fetchNote.type, runFetchNote);
  yield takeEvery(saveNote.type, runSaveNote);
  yield takeEvery(deleteNote.type, runDeleteNote);

  yield takeEvery(fetchProducts.type, runFetchProducts);
  yield takeEvery(fetchProduct.type, runFetchProduct);
  yield takeEvery(saveProduct.type, runSaveProduct);

  // yield takeEvery(fetchPipelinesAdmin.type, runFetchPipelinesAdmin);
  // yield takeEvery(fetchPipelines.type, runFetchPipelines);

  yield takeEvery(fetchPipelinesList.type, runFetchPipelinesList);
  yield takeEvery(savePipelinesList.type, runSavePipelinesList);
  yield takeEvery(savePipelineList.type, runSavePipelineList);

  yield takeEvery(fetchPipeline.type, runFetchPipeline);
  yield takeEvery(savePipeline.type, runSavePipeline);

  yield takeEvery(fetchActionTypes.type, runFetchActionTypes);

  yield takeEvery(fetchTodoTypes.type, runFetchTodoTypes);

  yield takeEvery(fetchTodoStatus.type, runFetchTodoStatus);

  yield takeEvery(fetchB2BCompanyData.type, runFetchB2BCompanyData);

  yield takeEvery(fetchFrontPage.type, runFetchFrontPage);

  yield takeEvery(saveVatnumberClient.type, runSaveVatnumberClient);
}

export default customersSaga;
