import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface publicState {
  accept_privacy: boolean;
  first_visit: undefined | Date;
  last_visit: undefined | Date;
  form_send: undefined | Date;
  form_success: undefined | "error" | Date;
  form_data: any;
}

const initialState: publicState = {
  accept_privacy: false,
  first_visit: undefined,
  last_visit: undefined,
  form_send: undefined,
  form_success: undefined,
  form_data: undefined,
};

const publicSlice = createSlice({
  name: "public",
  initialState,
  reducers: {
    setPrivacy: (state: publicState, action: PayloadAction<boolean>) => {
      state.accept_privacy = action.payload;
    },
    setFirstVisit: (state: publicState) => {
      state.first_visit = new Date();
    },
    setLastVisit: (state: publicState) => {
      state.last_visit = new Date();
    },
    sendForm: (state: publicState, action: PayloadAction<any>) => {
      state.form_send = new Date();
    },
    formSuccess: (
      state: publicState,
      action: PayloadAction<Date | "error" | undefined>
    ) => {
      state.form_success = action.payload;
    },
    setFormData: (state: publicState, action: PayloadAction<any>) => {
      state.form_data = action.payload;
    },
    resetForm: (state: publicState, action?: PayloadAction<boolean>) => {
      state.form_send = undefined;
      state.form_success = undefined;
      if (action && action.payload === true) state.form_data = undefined;
    },
  },
});

export const {
  setPrivacy,
  setFirstVisit,
  setLastVisit,
  sendForm,
  formSuccess,
  resetForm,
  setFormData,
} = publicSlice.actions;

export default publicSlice;
