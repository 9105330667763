import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { message } from "antd";

interface ICustomer {
  id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  company: string;
  vatnumber: string;
  address: string;
  zipcode: string;
  city: string;
  phone: string;
  email: string;
  information: string;
  sha: string;
  linkedin: string;
  www: string;
}

interface IFrontpage {
  todos: any;
  deals: any;
  leads: any;
}

interface IB2BCompany {
  name: string;
  value: string;
}

export interface IAction {
  id: number;
  customer_id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  action_type_id: number;
  text: string;
  action: string;
  contactname: string;
}

interface IAttachment {
  id: number;
  customer_id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  filename: string;
  file_category_id: number;
  text: string;
  remove: number;
  sha: string;
  origfilename: string;
  filetype: string;
}

interface IContact {
  id: number;
  customer_id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  name: string;
  phone: string;
  email: string;
  title: string;
}

interface IDeal {
  id: number;
  customer_id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  subject: string;
  text: string;
  amount: string;
  customer_pipeline_id: number;
  customer_product_id: number;
  close_at: number;
  product: string;
  pipeline: string;
  company: string;
}

export interface ITodo {
  id: number;
  client_id: number;
  customer_id: number;
  lead_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  status_id: number;
  todo_type_id: number;
  subject: string;
  text: string;
  deadline_at: number;
  customer_contact_id: number;
  contactname: string;
  companyname: string;
  status: string;
  type: string;
  deadline: number;
}

interface INote {
  id: number;
  client_id: number;
  customer_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  note: string;
}

interface IProduct {
  id: number;
  client_id: number;
  name: string;
}

export interface IPipeline {
  id?: number;
  client_id?: number;
  name: string;
  used: number;
  customer_pipeline_class_id: number;
  order_num?: number;
}

export interface IPipelineList {
  id: number;
  client_id: number;
  name: string;
  customer_pipelines: IPipeline[];
}

interface IActiontype {
  id: number;
  name: string;
  type_id: number;
}

interface ITodotype {
  id: number;
  name: string;
}

interface ITodostatus {
  id: number;
  name: string;
}
export interface CustomerState {
  customers: Array<ICustomer> | undefined;
  customer: ICustomer | undefined;
  actions: Array<IAction> | undefined;
  action: IAction | undefined;
  attachments: Array<IAttachment> | undefined;
  attachment: IAttachment | undefined;
  contacts: Array<IContact> | undefined;
  contact: IContact | undefined;
  deals: Array<IDeal> | undefined;
  deal: IDeal | undefined;
  todos: Array<ITodo> | undefined;
  todo: ITodo | undefined;
  notes: Array<INote> | undefined;
  note: INote | undefined;
  products: Array<IProduct> | undefined;
  product: IProduct | undefined;
  //pipelines: Array<IPipeline> | undefined;
  pipelinesList: Array<IPipelineList> | undefined;
  pipelineList: IPipelineList | undefined;
  pipeline: IPipeline | undefined;
  actiontypes: Array<IActiontype> | undefined;
  todotypes: Array<ITodotype> | undefined;
  todostatus: Array<ITodostatus> | undefined;
  b2bcompanydata: Array<IB2BCompany> | undefined;
  frontpage: IFrontpage | undefined;
  vatclient: number;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  customer_id?: number;
  filter?: any;
}

export interface IPutPipelines {
  token: string | undefined;
  pipelines: IPipelineList[];
}

const initialState: CustomerState = {
  customers: undefined,
  customer: undefined,
  actions: undefined,
  action: undefined,
  attachments: undefined,
  attachment: undefined,
  contacts: undefined,
  contact: undefined,
  deals: undefined,
  deal: undefined,
  todos: undefined,
  todo: undefined,
  notes: undefined,
  note: undefined,
  products: undefined,
  product: undefined,
  //pipelines: undefined,
  pipelinesList: undefined,
  pipelineList: undefined,
  pipeline: undefined,
  actiontypes: undefined,
  todotypes: undefined,
  todostatus: undefined,
  b2bcompanydata: undefined,
  frontpage: undefined,
  vatclient: -1,
};

const msg = message;

function messageClear() {
  setTimeout(function () {
    msg.destroy();
  }, 500);
}

const CustomersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    fetchCustomers: (state: CustomerState, action: PayloadAction<any>) => {
      msg.loading("Haetaan tietoja...", 0);
    },
    fetchCustomersSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<ICustomer>>
    ) => {
      messageClear();
      state.customers = action.payload;
    },
    fetchCustomer: (state: CustomerState, action: PayloadAction<IPut>) => {
      msg.loading("Haetaan tietoja...", 0);
    },
    fetchCustomerSucceeded: (
      state: CustomerState,
      action: PayloadAction<ICustomer>
    ) => {
      messageClear();
      state.customer = action.payload;
    },
    saveCustomer: (
      state: CustomerState,
      action: PayloadAction<ICustomer>
    ) => {},
    saveCustomerSucceeded: (
      state: CustomerState,
      action: PayloadAction<ICustomer>
    ) => {
      state.customer = action.payload;
    },
    deleteCustomer: (state: CustomerState, action: PayloadAction<IPut>) => {},
    deleteCustomerSucceeded: (
      state: CustomerState,
      action: PayloadAction<ICustomer>
    ) => {
      state.customer = undefined;
    },

    fetchActions: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchActionsSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IAction>>
    ) => {
      state.actions = action.payload;
    },
    fetchAction: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchActionSucceeded: (
      state: CustomerState,
      action: PayloadAction<IAction>
    ) => {
      state.action = action.payload;
    },
    saveAction: (state: CustomerState, action: PayloadAction<IAction>) => {},
    saveActionSucceeded: (
      state: CustomerState,
      action: PayloadAction<IAction>
    ) => {
      state.action = undefined;
      state.actiontypes = undefined;
    },

    fetchAttachments: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchAttachmentsSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IAttachment>>
    ) => {
      state.attachments = action.payload;
    },
    fetchAttachment: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchAttachmentSucceeded: (
      state: CustomerState,
      action: PayloadAction<IAttachment>
    ) => {
      state.attachment = action.payload;
    },
    saveAttachment: (
      state: CustomerState,
      action: PayloadAction<IAttachment>
    ) => {},
    saveAttachmentSucceeded: (
      state: CustomerState,
      action: PayloadAction<IAttachment>
    ) => {
      state.attachment = undefined;
    },
    deleteAttachment: (state: CustomerState, action: PayloadAction<IPut>) => {},
    deleteAttachmentSucceeded: (
      state: CustomerState,
      action: PayloadAction<IAttachment>
    ) => {
      state.attachment = undefined;
    },

    clearAttachment: (state: CustomerState) => {
      state.attachment = undefined;
    },

    fetchContacts: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchContactsSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IContact>>
    ) => {
      state.contacts = action.payload;
    },
    clearContacts: (state: CustomerState) => {
      state.contacts = undefined;
    },
    fetchContact: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchContactSucceeded: (
      state: CustomerState,
      action: PayloadAction<IContact>
    ) => {
      state.contact = action.payload;
    },
    clearContact: (state: CustomerState) => {
      state.contact = undefined;
    },
    saveContact: (state: CustomerState, action: PayloadAction<IContact>) => {},
    saveContactSucceeded: (
      state: CustomerState,
      action: PayloadAction<IContact>
    ) => {
      state.contact = undefined;
    },
    deleteContact: (state: CustomerState, action: PayloadAction<IPut>) => {},
    deleteContactSucceeded: (
      state: CustomerState,
      action: PayloadAction<IContact>
    ) => {
      state.contact = undefined;
    },

    fetchDeals: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchDealsSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IDeal>>
    ) => {
      state.deals = action.payload;
    },
    fetchDeal: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchDealSucceeded: (
      state: CustomerState,
      action: PayloadAction<IDeal>
    ) => {
      state.deal = action.payload;
    },
    saveDeal: (state: CustomerState, action: PayloadAction<IDeal>) => {},
    clearDeal: (state: CustomerState) => {
      state.deal = undefined;
    },
    saveDealSucceeded: (state: CustomerState, action: PayloadAction<IDeal>) => {
      state.deal = undefined;
    },
    deleteDeal: (state: CustomerState, action: PayloadAction<IPut>) => {},
    deleteDealSucceeded: (
      state: CustomerState,
      action: PayloadAction<IDeal>
    ) => {
      state.deal = undefined;
    },

    fetchTodos: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchTodosSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<ITodo>>
    ) => {
      state.todos = action.payload;
    },
    fetchTodo: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchTodoSucceeded: (
      state: CustomerState,
      action: PayloadAction<ITodo>
    ) => {
      state.todo = action.payload;
    },
    clearTodo: (state: CustomerState) => {
      state.todo = undefined;
    },
    saveTodo: (state: CustomerState, action: PayloadAction<ITodo>) => {},
    saveTodoSucceeded: (state: CustomerState, action: PayloadAction<ITodo>) => {
      state.todos = undefined;
      state.todo = undefined;
    },
    deleteTodo: (state: CustomerState, action: PayloadAction<IPut>) => {},
    deleteTodoSucceeded: (
      state: CustomerState,
      action: PayloadAction<ITodo>
    ) => {
      state.todos = undefined;
      state.todo = undefined;
    },

    fetchNotes: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchNotesSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<INote>>
    ) => {
      state.notes = action.payload;
    },
    fetchNote: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchNoteSucceeded: (
      state: CustomerState,
      action: PayloadAction<INote>
    ) => {
      state.note = action.payload;
    },
    clearNote: (state: CustomerState) => {
      state.note = undefined;
    },
    saveNote: (state: CustomerState, action: PayloadAction<INote>) => {},
    saveNoteSucceeded: (state: CustomerState, action: PayloadAction<INote>) => {
      state.note = undefined;
    },
    deleteNote: (state: CustomerState, action: PayloadAction<IPut>) => {},
    deleteNoteSucceeded: (
      state: CustomerState,
      action: PayloadAction<INote>
    ) => {
      state.note = undefined;
    },

    fetchProducts: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchProductsSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IProduct>>
    ) => {
      state.products = action.payload;
    },
    fetchProduct: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchProductSucceeded: (
      state: CustomerState,
      action: PayloadAction<IProduct>
    ) => {
      state.product = action.payload;
    },
    saveProduct: (state: CustomerState, action: PayloadAction<IProduct>) => {},
    clearProduct: (state: CustomerState) => {
      state.product = undefined;
    },
    saveProductSucceeded: (
      state: CustomerState,
      action: PayloadAction<IProduct>
    ) => {
      state.product = undefined;
    },

    fetchPipelines: (state: CustomerState, action: PayloadAction<IPut>) => {},
    /*fetchPipelinesSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IPipeline>>
    ) => {
      state.pipelines = action.payload;
    },*/
    fetchPipelinesAdmin: (
      state: CustomerState,
      action: PayloadAction<IPut>
    ) => {},
    fetchPipelinesList: (
      state: CustomerState,
      action: PayloadAction<IPut>
    ) => {},
    setPipelinesList: (
      state: CustomerState,
      action: PayloadAction<IPipelineList[]>
    ) => {
      state.pipelinesList = action.payload;
    },
    savePipelinesList: (
      state: CustomerState,
      action: PayloadAction<IPutPipelines>
    ) => {
      state.pipelinesList = action.payload.pipelines;
    },
    savePipelineList: (state: CustomerState, action: PayloadAction<IPut>) => {},
    setPipelineList: (
      state: CustomerState,
      action: PayloadAction<IPipelineList | undefined>
    ) => {
      state.pipelineList = action.payload;
    },
    /* fetchPipelinesAdminSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IPipeline>>
    ) => {
      state.pipelines = action.payload;
    },*/
    fetchPipeline: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchPipelineSucceeded: (
      state: CustomerState,
      action: PayloadAction<IPipeline>
    ) => {
      state.pipeline = action.payload;
    },
    savePipeline: (
      state: CustomerState,
      action: PayloadAction<IPipeline>
    ) => {},
    clearPipeline: (state: CustomerState) => {
      state.pipeline = undefined;
    },
    savePipelineSucceeded: (
      state: CustomerState,
      action: PayloadAction<IPipeline>
    ) => {
      state.pipeline = undefined;
    },
    fetchActionTypes: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchActionTypesSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IActiontype>>
    ) => {
      state.actiontypes = action.payload;
    },
    fetchTodoTypes: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchTodoTypesSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<ITodotype>>
    ) => {
      state.todotypes = action.payload;
    },
    clearActionTypes: (state: CustomerState, action: PayloadAction<any>) => {
      state.actiontypes = undefined;
    },

    fetchTodoStatus: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchTodoStatusSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<ITodostatus>>
    ) => {
      state.todostatus = action.payload;
    },
    fetchB2BCompanyData: (
      state: CustomerState,
      action: PayloadAction<IPut>
    ) => {},
    fetchB2BCompanyDataSucceeded: (
      state: CustomerState,
      action: PayloadAction<Array<IB2BCompany>>
    ) => {
      state.b2bcompanydata = action.payload;
    },

    clearB2BCompanyData: (state: CustomerState, action: PayloadAction<any>) => {
      state.b2bcompanydata = undefined;
    },
    fetchFrontPage: (state: CustomerState, action: PayloadAction<IPut>) => {},
    fetchFrontPageSucceeded: (
      state: CustomerState,
      action: PayloadAction<IFrontpage>
    ) => {
      state.frontpage = action.payload;
    },

    saveVatnumberClient: (
      state: CustomerState,
      action: PayloadAction<any>
    ) => {},
    saveVatnumberClientSucceeded: (
      state: CustomerState,
      action: PayloadAction<any>
    ) => {
      state.vatclient = action.payload;
    },
  },
});

export const {
  fetchCustomers,
  fetchCustomersSucceeded,
  fetchCustomer,
  fetchCustomerSucceeded,
  saveCustomer,
  saveCustomerSucceeded,
  fetchActions,
  fetchActionsSucceeded,
  fetchAction,
  fetchActionSucceeded,
  saveAction,
  saveActionSucceeded,
  fetchAttachments,
  fetchAttachmentsSucceeded,
  fetchAttachment,
  fetchAttachmentSucceeded,
  saveAttachment,
  saveAttachmentSucceeded,
  clearAttachment,
  deleteAttachment,
  deleteAttachmentSucceeded,
  fetchContacts,
  fetchContactsSucceeded,
  clearContacts,
  fetchContact,
  fetchContactSucceeded,
  clearContact,
  saveContact,
  saveContactSucceeded,
  deleteContact,
  deleteContactSucceeded,
  fetchDeals,
  fetchDealsSucceeded,
  fetchDeal,
  fetchDealSucceeded,
  clearDeal,
  saveDeal,
  saveDealSucceeded,
  deleteDeal,
  deleteDealSucceeded,
  fetchTodos,
  fetchTodosSucceeded,
  fetchTodo,
  fetchTodoSucceeded,
  clearTodo,
  saveTodo,
  saveTodoSucceeded,
  deleteTodo,
  deleteTodoSucceeded,
  fetchNotes,
  fetchNotesSucceeded,
  fetchNote,
  fetchNoteSucceeded,
  clearNote,
  saveNote,
  saveNoteSucceeded,
  deleteNote,
  deleteNoteSucceeded,
  fetchProducts,
  fetchProductsSucceeded,
  fetchProduct,
  fetchProductSucceeded,
  saveProduct,
  clearProduct,
  saveProductSucceeded,
  fetchPipelines,
  //fetchPipelinesSucceeded,
  //fetchPipelinesAdmin,
  fetchPipelinesList,
  setPipelinesList,
  savePipelinesList,
  savePipelineList,
  setPipelineList,
  //fetchPipelinesAdminSucceeded,
  fetchPipeline,
  fetchPipelineSucceeded,
  savePipeline,
  clearPipeline,
  savePipelineSucceeded,
  fetchActionTypes,
  fetchActionTypesSucceeded,
  clearActionTypes,
  fetchTodoTypes,
  fetchTodoTypesSucceeded,
  fetchTodoStatus,
  fetchTodoStatusSucceeded,
  fetchB2BCompanyData,
  fetchB2BCompanyDataSucceeded,
  fetchFrontPage,
  fetchFrontPageSucceeded,
  deleteCustomer,
  deleteCustomerSucceeded,
  saveVatnumberClient,
  saveVatnumberClientSucceeded,
  clearB2BCompanyData,
} = CustomersSlice.actions;

export default CustomersSlice;
