import axios from "axios";
import React from "react";
import { startLogout } from "../reducers/userData";
import { AppState } from "../reducers/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { useHistory } from "react-router-dom";

export let backend_address = "https://api.leadtrigger.fi";

// Add config for axios (base url, proxy...) here
// More details on https://github.com/axios/axios#request-config
let backend = axios.create({
  baseURL: "https://api.leadtrigger.fi",
  // proxy: ...
});

backend.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (
      error.response.status == 401 &&
      error.response.config.url != "/users/login"
    ) {
      window.location.href = "/logout";
    }
    return Promise.reject(error);
  }
);

export default backend;
