import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchAccounts,
  fetchAccountsSucceeded,
  fetchAccount,
  fetchAccountSucceeded,
  saveAccount,
  saveAccountSucceeded,
  clearAccount,
  fetchSetting,
  fetchSettingSucceeded,
  fetchPages,
  fetchPagesSucceeded,
  savePage,
  savePageSucceeded,
  fetchCampaigns,
  fetchCampaignsSucceeded,
  saveCampaign,
  saveCampaignSucceeded,
  saveAd,
  saveAdSucceeded,
  fetchPreview,
  fetchPreviewSucceeded,
  fetchAds,
  fetchAd,
  setAd,
  fetchAdPreview,
  setAdPreview,
  fetchAdsSucceeded,
  saveCampaignAdsetAd,
  saveCampaignAdsetAdSucceeded,
  fetchAdsets,
  fetchAdsetsSucceeded,
  IPut,
  fetchAudiences,
  setAudiences,
  updateCampaignStatus,
  updateCampaignStatusSucceeded,
  updateCampaign,
  updateCampaignSucceeded,
  fetchAdTemplates,
  fetchAdTemplatesSucceeded,
  listPersonParams,
  listPersonParamsSucceeded,
  searchLocations,
  searchLocationsSucceeded,
  getSomeReports,
  getSomeReportsSucceeded,
} from "../reducers/facebookSlice";
import { backend_address } from "../config/axios";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchAccounts(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/listAccounts", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );

    yield put(fetchAccountsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAccount(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAccountSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveAccount(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/saveAccount", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveAccountSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchSetting(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/getSetting", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchSettingSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchPages(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/listPages", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchPagesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSavePage(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/savePage", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(savePageSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchCampaigns(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/getCampaigns", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchCampaignsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveCampaign(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/saveCampaign", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveCampaignSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveAd(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/saveAds", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveAdSucceeded(response));
    data.payload.id = response.id;
    yield runFetchPreview(data);
  } catch (e) {
    // handle errors
  }
}

function* runFetchPreview(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/getPreview",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchPreviewSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAds(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/listAds", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchAdsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAd(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/get",
        { creative_id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(setAd(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAudiences(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/getCustomAudiances", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(setAudiences(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAdPreview(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/generatePreview", data.payload.preview, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(setAdPreview(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveCampaignAdsetAd(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/createNew", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveCampaignAdsetAdSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAdsets(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/listAdSet",
        { campaign_id: data.payload.campaign_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAdsetsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runUpdateCampaignStatus(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/updateStatus", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(updateCampaignStatusSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runUpdateCampaign(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Facebook/updateCampaign", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(updateCampaignSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAdTemplates(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/listAdTemplates",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAdTemplatesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runListPersonParams(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/listPersonParams",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(listPersonParamsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSearchLocations(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Facebook/searchLocation",
        { search_text: data.payload.search_text },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(searchLocationsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runGetSomeReports(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/SomeReports/list", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(getSomeReportsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* facebookSaga() {
  yield takeEvery(fetchAccounts.type, runFetchAccounts);
  yield takeEvery(fetchAccount.type, runFetchAccount);
  yield takeEvery(saveAccount.type, runSaveAccount);

  yield takeEvery(fetchPages.type, runFetchPages);
  yield takeEvery(savePage.type, runSavePage);

  yield takeEvery(fetchCampaigns.type, runFetchCampaigns);
  yield takeEvery(saveCampaign.type, runSaveCampaign);

  yield takeEvery(fetchSetting.type, runFetchSetting);

  yield takeEvery(fetchAds.type, runFetchAds);
  yield takeEvery(fetchAd.type, runFetchAd);
  yield takeEvery(saveAd.type, runSaveAd);
  yield takeEvery(fetchPreview.type, runFetchPreview);
  yield takeEvery(fetchAdPreview.type, runFetchAdPreview);

  yield takeEvery(saveCampaignAdsetAd.type, runSaveCampaignAdsetAd);

  yield takeEvery(fetchAdsets.type, runFetchAdsets);

  yield takeEvery(fetchAudiences.type, runFetchAudiences);

  yield takeEvery(updateCampaignStatus.type, runUpdateCampaignStatus);
  yield takeEvery(updateCampaign.type, runUpdateCampaign);

  yield takeEvery(fetchAdTemplates.type, runFetchAdTemplates);

  yield takeEvery(listPersonParams.type, runListPersonParams);

  yield takeEvery(searchLocations.type, runSearchLocations);

  yield takeEvery(getSomeReports.type, runGetSomeReports);
}

export default facebookSaga;
