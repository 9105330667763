import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IClient {
  id: number;
  company: string;
  vatnumber: string;
  address: string;
  zipcode: string;
  city: string;
  contact: string;
  phone: string;
  email: string;
  created_at: number;
  modified_at: number;
  status_id: number;
  tracking_code: string;
  domain: string;
  domain_status: number;
  contract_at: number;
  invoice_date: number;
  billing_cycle: number;
  invoice_amount: string;
  invoice_information: string;
  token: string;
}

interface IUser {
  id: number;
  name: string;
  username: string;
  role: string;
  client_id: number;
  password: string;
}

interface IDomain {
  domain: string;
  price: string;
}

interface IDomainPrice {
  domain: string;
  price: string;
  status: string;
}

interface ILead {
  id: number;
  client_id: number;
  modified_at: number;
  type: number;
  users: any;
}

interface ISetting {
  id: number;
  client_id: number;
  name: string;
  value: any;
  long_name: string;
}

interface IMessage {
  id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  subject: string;
  message: string;
  read_status: number;
}

interface IMessageCount {
  sent_messages: number;
  to_be_sent: number;
}

export interface ClientState {
  clients: Array<IClient> | undefined;
  client: IClient | undefined;
  users: Array<IUser> | undefined;
  user: IUser | undefined;
  settings: Array<ISetting> | undefined;
  setting: ISetting | undefined;
  usersettings: Array<ISetting> | undefined;
  usersetting: ISetting | undefined;
  messages: Array<IMessage> | undefined;
  message: IMessage | undefined;
  messageCount: IMessageCount | undefined;
  leadshare: ILead | undefined;
  domains: Array<IDomain> | undefined;
  price: IDomainPrice | undefined;
  mailchimp: string | undefined;
  linkedin: string | undefined;
  facebook: string | undefined;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  name?: string;
  client_id?: number;
  search?: string;
  domain?: string;
}

const initialState: ClientState = {
  clients: undefined,
  client: undefined,
  users: undefined,
  user: undefined,
  settings: undefined,
  setting: undefined,
  usersettings: undefined,
  usersetting: undefined,
  messages: undefined,
  message: undefined,
  messageCount: undefined,
  leadshare: undefined,
  domains: undefined,
  price: undefined,
  mailchimp: undefined,
  linkedin: undefined,
  facebook: undefined,
};

const ClientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    fetchMailchimp: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchMailchimpSucceeded: (
      state: ClientState,
      action: PayloadAction<any>
    ) => {
      state.mailchimp = action.payload;
    },
    fetchLinkedIn: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchLinkedInSucceeded: (
      state: ClientState,
      action: PayloadAction<any>
    ) => {
      state.linkedin = action.payload;
    },
    fetchFacebook: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchFacebookSucceeded: (
      state: ClientState,
      action: PayloadAction<any>
    ) => {
      state.facebook = action.payload;
    },

    fetchClients: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchClientsSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<IClient>>
    ) => {
      state.clients = action.payload;
    },

    fetchClient: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchClientSucceeded: (
      state: ClientState,
      action: PayloadAction<IClient>
    ) => {
      state.client = action.payload;
    },
    saveClient: (state: ClientState, action: PayloadAction<IClient>) => {},
    saveClientSucceeded: (
      state: ClientState,
      action: PayloadAction<IClient>
    ) => {
      state.client = action.payload;
    },
    fetchUsers: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchUsersSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<IUser>>
    ) => {
      state.users = action.payload;
    },

    fetchUser: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchUserSucceeded: (state: ClientState, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    saveUser: (state: ClientState, action: PayloadAction<IUser>) => {},
    clearUser: (state: ClientState) => {
      state.user = undefined;
    },
    saveUserSucceeded: (state: ClientState, action: PayloadAction<IUser>) => {
      state.user = undefined;
    },
    deleteUser: (state: ClientState, action: PayloadAction<IPut>) => {},
    deleteUserSucceeded: (state: ClientState, action: PayloadAction<IUser>) => {
      state.user = undefined;
    },

    fetchClientSettings: (
      state: ClientState,
      action: PayloadAction<IPut>
    ) => {},
    fetchClientSettingsSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<ISetting>>
    ) => {
      state.settings = action.payload;
    },

    fetchClientSetting: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchClientSettingSucceeded: (
      state: ClientState,
      action: PayloadAction<ISetting>
    ) => {
      state.setting = action.payload;
    },
    saveClientSetting: (
      state: ClientState,
      action: PayloadAction<ISetting>
    ) => {},
    clearClientSetting: (state: ClientState) => {
      state.setting = undefined;
    },
    saveClientSettingSucceeded: (
      state: ClientState,
      action: PayloadAction<ISetting>
    ) => {
      state.setting = undefined;
    },

    fetchUserSettings: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchUserSettingsSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<ISetting>>
    ) => {
      state.usersettings = action.payload;
    },

    fetchUserSetting: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchUserSettingSucceeded: (
      state: ClientState,
      action: PayloadAction<ISetting>
    ) => {
      state.usersetting = action.payload;
    },
    saveUserSetting: (
      state: ClientState,
      action: PayloadAction<ISetting>
    ) => {},
    clearUserSetting: (state: ClientState) => {
      state.usersetting = undefined;
    },
    saveUserSettingSucceeded: (
      state: ClientState,
      action: PayloadAction<ISetting>
    ) => {
      state.usersetting = undefined;
    },

    fetchMessages: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchMessagesSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<IMessage>>
    ) => {
      state.messages = action.payload;
    },
    fetchMessage: (state: ClientState, action: PayloadAction<IPut>) => {},
    clearMessage: (state: ClientState) => {
      state.message = undefined;
    },
    fetchMessageSucceeded: (
      state: ClientState,
      action: PayloadAction<IMessage>
    ) => {
      state.message = action.payload;
    },

    fetchMessageCount: (state: ClientState, action: PayloadAction<IPut>) => {},
    setMessageCount: (
      state: ClientState,
      action: PayloadAction<IMessageCount | undefined>
    ) => {
      state.messageCount = action.payload;
    },

    fetchLeadShare: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchLeadShareSucceeded: (
      state: ClientState,
      action: PayloadAction<ILead>
    ) => {
      state.leadshare = action.payload;
    },

    saveLeadShare: (state: ClientState, action: PayloadAction<ILead>) => {},
    saveLeadShareSucceeded: (
      state: ClientState,
      action: PayloadAction<ILead>
    ) => {
      state.leadshare = action.payload;
    },

    fetchUsersAdmin: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchUsersAdminSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<IUser>>
    ) => {
      state.users = action.payload;
    },

    fetchUserAdmin: (state: ClientState, action: PayloadAction<IPut>) => {},
    fetchUserAdminSucceeded: (
      state: ClientState,
      action: PayloadAction<IUser>
    ) => {
      state.user = action.payload;
    },
    saveUserAdmin: (state: ClientState, action: PayloadAction<IUser>) => {},
    saveUserAdminSucceeded: (
      state: ClientState,
      action: PayloadAction<IUser>
    ) => {
      state.user = undefined;
    },
    deleteUserAdmin: (state: ClientState, action: PayloadAction<IPut>) => {},
    deleteUserAdminSucceeded: (
      state: ClientState,
      action: PayloadAction<IUser>
    ) => {
      state.user = undefined;
    },

    searchDomain: (state: ClientState, action: PayloadAction<IDomain>) => {},
    searchDomainSucceeded: (
      state: ClientState,
      action: PayloadAction<Array<IDomain>>
    ) => {
      state.domains = action.payload;
    },

    fetchDomainPrice: (state: ClientState, action: PayloadAction<IPut>) => {},
    clearDomainPrice: (state: ClientState) => {
      state.price = undefined;
    },
    fetchDomainPriceSucceeded: (
      state: ClientState,
      action: PayloadAction<IDomainPrice>
    ) => {
      state.price = action.payload;
    },

    saveDomain: (state: ClientState, action: PayloadAction<IPut>) => {},
    saveDomainSucceeded: (state: ClientState, action: PayloadAction<any>) => {
      state.price = undefined;
      state.domains = undefined;
    },
  },
});

export const {
  fetchClients,
  fetchClientsSucceeded,
  fetchClient,
  fetchClientSucceeded,
  saveClient,
  saveClientSucceeded,
  fetchUsers,
  fetchUsersSucceeded,
  fetchUser,
  fetchUserSucceeded,
  saveUser,
  clearUser,
  saveUserSucceeded,
  fetchClientSettings,
  fetchClientSettingsSucceeded,
  fetchClientSetting,
  fetchClientSettingSucceeded,
  saveClientSetting,
  clearClientSetting,
  saveClientSettingSucceeded,
  fetchUserSettings,
  fetchUserSettingsSucceeded,
  fetchUserSetting,
  fetchUserSettingSucceeded,
  saveUserSetting,
  clearUserSetting,
  saveUserSettingSucceeded,
  fetchMessages,
  fetchMessagesSucceeded,
  fetchMessage,
  clearMessage,
  fetchMessageSucceeded,

  fetchMessageCount,
  setMessageCount,

  fetchLeadShare,
  fetchLeadShareSucceeded,
  saveLeadShare,
  saveLeadShareSucceeded,
  deleteUser,
  deleteUserSucceeded,

  fetchUsersAdmin,
  fetchUsersAdminSucceeded,
  fetchUserAdmin,
  fetchUserAdminSucceeded,
  saveUserAdmin,
  saveUserAdminSucceeded,
  deleteUserAdmin,
  deleteUserAdminSucceeded,

  searchDomain,
  searchDomainSucceeded,

  fetchDomainPrice,
  clearDomainPrice,
  fetchDomainPriceSucceeded,
  saveDomain,
  saveDomainSucceeded,

  fetchMailchimp,
  fetchMailchimpSucceeded,

  fetchLinkedIn,
  fetchLinkedInSucceeded,

  fetchFacebook,
  fetchFacebookSucceeded,
} = ClientsSlice.actions;

export default ClientsSlice;
