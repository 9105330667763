import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";

import {
  setFirstVisit,
  sendForm,
  formSuccess,
  setFormData,
} from "../reducers/publicSlice";

function* runSendForm(data: any) {
  yield put(setFormData(data.payload));
  try {
    const response = yield call(() =>
      backend.post("/www/saveContact", data.payload, {})
    );
    yield put(formSuccess(new Date()));
  } catch (e) {
    // handle errors
    yield put(formSuccess("error"));
  }
}

function* publicSaga() {
  yield takeEvery(sendForm.type, runSendForm);
}

export default publicSaga;
