import React, { lazy } from "react";
import { AppState } from "../reducers/rootReducer";
import { useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Button, Menu } from "antd";

import Icon from "@ant-design/icons";
import GroupIcon from "../customIcons/group.svg";
import {
  TeamOutlined,
  MailOutlined,
  AimOutlined,
  PieChartOutlined,
  SettingOutlined,
  UnlockOutlined,
  PoweroffOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

export interface MenuItem {
  title: string;
  path: string;
  component?: string;
  icon?: JSX.Element;
  id?: number;
  sub?: MenuItem[];
  hide?: Boolean;
}

const siteTitle = "LeadTrigger";

export const PageRoutes: MenuItem[] = [
  /*  {
    title: "Etusivu",
    path: "/",
    component: "Home/index",
  },
  {
    title: "Esimerkit",
    path: "/examples",
    icon: <span />,
    component: "Layout/index",
    hide: true,
  },
  {
    title: "Ota yhteyttä",
    path: "/contact-us",
    icon: <span />,
    component: "Public/contactForm",
    hide: true,
  },*/
  {
    title: "Tietosuojaseloste",
    path: "/privacy",
    icon: <span />,
    component: "Public/privacy",
    hide: true,
  },
  {
    title: "Kirjaudu",
    path: "/",
    icon: (
      <img
        style={{ width: "65px", fill: "#fff" }}
        className="fill-white p-4 opacity-50"
        src="/img/login.svg"
      />
    ),
    component: "User/login",
  },
];

const PageRoutesUser: MenuItem[] = [
  {
    title: "Etusivu",
    path: "/", //frontpage
    component: "Frontpage/frontpage",
    hide: true,
  },
  {
    title: "CRM",
    path: "/crm",
    icon: (
      <img
        style={{ width: "65px", fill: "#fff" }}
        className="fill-white p-4 opacity-50"
        src="/img/crm.svg"
      />
    ),
    //<TeamOutlined />,
    sub: [
      {
        title: "Liidien hallinta",
        path: "/leads",
        component: "Crm/leads",
      },
      {
        title: "Diilit",
        path: "/deals",
        component: "Crm/deals",
      },
      {
        title: "Tehtävät",
        path: "/todos",
        component: "Crm/todos",
      },
      {
        title: "Asiakashaku",
        path: "/csearch",
        component: "Crm/search",
      },
      {
        title: "Soittolista",
        path: "/call",
        component: "Crm/call",
      },
      {
        title: "Yrityshaku",
        path: "/companysearch",
        component: "Crm/companysearch",
      },
      {
        title: "Uusi asiakas",
        path: "/addcustomer",
        component: "Crm/addcustomer",
        hide: true,
      },
    ],
  },
  {
    title: "Viestipohjat",
    path: "/messagetemplates",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/mail.svg"
      />
    ),
    //<MailOutlined />,
    sub: [
      {
        title: "Sähköpostipohjat",
        path: "/email",
        component: "EmailMarketing/templates",
      },
    ],
  },
  {
    title: "Some",
    path: "/some",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/marketing.svg"
      />
    ),
    //<MailOutlined />,
    sub: [
      {
        title: "Facebook mainonta",
        path: "/facebook",
        component: "Some/facebook",
      },
      {
        title: "Facebook kampanja",
        path: "/testi",
        component: "Some/testi",
        hide: true,
      },
      {
        title: "Facebook kampanja",
        path: "/facebookdetail",
        component: "Some/facebookDetail",
        hide: true,
      },
      {
        title: "Uusi Facebook kampanja",
        path: "/facebooknew",
        component: "Some/facebookNew",
        hide: true,
      },
      {
        title: "LinkedIn mainonta",
        path: "/linkedin",
        component: "Some/linkedin",
      },
      {
        title: "LinkedIn kampanja",
        path: "/linkedindetail",
        component: "Some/linkedinDetail",
        hide: true,
      },
      {
        title: "Uusi LinkedIn kampanja",
        path: "/linkedinnew",
        component: "Some/linkedinNew",
        hide: true,
      },
      {
        title: "Raportti",
        path: "/reports",
        component: "Some/reports",
      },
    ],
  },

  {
    title: "Kohderyhmät",
    path: "/contacts",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/targetgroup.svg"
      />
    ),
    sub: [
      {
        title: "Kohderyhmät",
        path: "/lists",
        component: "Contactsearch/list",
      },
      {
        title: "Ladattavat listat",
        path: "/excellist",
        component: "Contactsearch/excellist",
        hide: true,
      },
      {
        title: "Y-tunnus haku",
        path: "/vatnumber",
        component: "Contactsearch/vatnumber",
        hide: true,
      },
      {
        title: "B2B-haku",
        path: "/b2bsearch",
        component: "Contactsearch/search",
        hide: true,
      },
      {
        title: "Excel viesti",
        path: "/excelexport",
        component: "Contactsearch/excelexport",
        hide: true,
      },
      /*      {
        title: "Luo lista omista yhteystiedoista",
        path: "/private",
        component: "Contactsearch/private",
        //path: "/private",
        //component: "EmailMarketing/createcontact",
        hide: true,
      },
*/
      {
        title: "Ladattavan listan luominen",
        path: "/editexportlist",
        component: "Contactsearch/editexportlist",
        hide: true,
      },
      {
        title: "Muokkaa kohderyhmää",
        path: "/b2beditlist",
        component: "Contactsearch/b2beditlist",
        hide: true,
      },
    ],
  },
  {
    title: "Asetukset",
    path: "/crmadmin",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/settings.svg"
      />
    ),
    //<SettingOutlined />,
    sub: [
      {
        title: "Käyttäjät",
        path: "/users",
        component: "Crmadmin/user",
      },
      {
        title: "Integraatiot",
        path: "/integrations",
        component: "Integrations/integrations",
      },
      {
        title: "MailChimp integraatio",
        path: "/mailchimpSuccess",
        component: "Integrations/mailchimpsuccess",
        hide: true,
      },
      {
        title: "LinkedIn integraatio",
        path: "/linkedinSuccess",
        component: "Integrations/linkedinsuccess",
        hide: true,
      },
      {
        title: "Facebook integraatio",
        path: "/facebookSuccess",
        component: "Integrations/facebooksuccess",
        hide: true,
      },
      {
        title: "Tuotteet",
        path: "/products",
        component: "Crmadmin/product",
      },
      {
        title: "Myyntipolku",
        path: "/pipeline",
        component: "Crmadmin/pipeline",
      },
      {
        title: "Viestit",
        path: "/messages",
        component: "Crmadmin/messages",
      },
      {
        title: "Liidien jakaminen",
        path: "/leadshare",
        component: "Crmadmin/leadshare",
      },
      {
        title: "Vaihda salasana",
        path: "/changepassword",
        component: "Crmadmin/changepassword",
      },
      {
        title: "Tuo asiakkaita",
        path: "/importcustomers",
        component: "Crmadmin/importcustomer",
      },
    ],
  },
  {
    title: "Kirjaudu ulos",
    path: "/logout",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/logout.svg"
      />
    ),
    //<LogoutOutlined />, //<PoweroffOutlined />, //<UnlockOutlined />,
    component: "User/logout",
  },
  /*{
    title: "Uusi B2B lista",
    path: "/contactsearch/b2bsearch",
    component: "Contactsearch/search",
    hide: true,
  },
  {
    title: "Luo kontakti lista",
    path: "/emailmarketing/add",
    component: "EmailMarketing/createcontact",
    hide: true,
  },*/
  {
    title: "Asiakassivu",
    path: "/crm/customer",
    component: "Crm/customer",
    hide: true,
  },
];

const PageRoutesSales: MenuItem[] = [
  {
    title: "Etusivu",
    path: "/", //frontpage
    component: "Frontpage/frontpage",
    hide: true,
  },
  {
    title: "CRM",
    path: "/crm",
    icon: (
      <img
        style={{ width: "65px", fill: "#fff" }}
        className="fill-white p-4 opacity-50"
        src="/img/crm.svg"
      />
    ),
    sub: [
      {
        title: "Diilit",
        path: "/deals",
        component: "Crm/deals",
      },
      {
        title: "Tehtävät",
        path: "/todos",
        component: "Crm/todos",
      },
      {
        title: "Asiakashaku",
        path: "/csearch",
        component: "Crm/search",
      },
      {
        title: "Soittolista",
        path: "/call",
        component: "Crm/call",
      },
      {
        title: "Uusi asiakas",
        path: "/addcustomer",
        component: "Crm/addcustomer",
        hide: true,
      },
    ],
  },
  {
    title: "Asetukset",
    path: "/crmadmin",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/settings.svg"
      />
    ),
    sub: [
      {
        title: "Vaihda salasana",
        path: "/changepassword",
        component: "Crmadmin/changepassword",
      },
    ],
  },
  {
    title: "Kirjaudu ulos",
    path: "/logout",
    icon: (
      <img
        style={{ width: "65px" }}
        className="fill-white p-4 opacity-50"
        src="/img/logout.svg"
      />
    ),
    component: "User/logout",
  },
  {
    title: "Asiakassivu",
    path: "/crm/customer",
    component: "Crm/customer",
    hide: true,
  },
];

const PageRoutesAdmin: MenuItem[] = [
  {
    title: "Ylläpito",
    path: "/admin",
    sub: [
      {
        title: "Asiakashaku",
        path: "/asearch",
        component: "Admin/search",
      },
      {
        title: "Uusi asiakas",
        path: "/addclient",
        component: "Admin/addclient",
      },
    ],
  },
  {
    title: "Kirjaudu ulos",
    path: "/logout",
    component: "User/logout",
  },

  {
    title: "Asiakassivu",
    path: "/admin/client",
    component: "Admin/client",
    hide: true,
  },
];

export const CreateRoutes = () => {
  const role = useSelector((state: AppState) => state.user.info?.role);
  const status = useSelector((state: AppState) => state.user.status?.type);

  function lazyLoadComponent(url?: string) {
    // Lazy loading main pages
    // console.log("url", url);
    /*useEffect(() => {
      let title = history.location.pathname
      document.title = title;
    });*/
    return lazy(() => import("./" + url));
  }

  let menuItems: MenuItem[] = PageRoutes;

  let history = useHistory();
  const location = useLocation();

  //console.log('url',history.location.pathname);

  if (
    status === "success" &&
    (Number(role) === 100 || Number(role) === 1 || Number(role) === 10)
  ) {
    if (location.pathname === "/login") history.push("/");
  }

  if (role) {
    if (Number(role) === 100) {
      menuItems = PageRoutesAdmin;
    } else if (Number(role) === 10) {
      menuItems = PageRoutesUser;
    } else if (Number(role) == 1) {
      menuItems = PageRoutesSales;
    }
  }

  const menuRoutes = menuItems.map((item: MenuItem, key: number) => {
    if (item.component) {
      /*const route = <Route key={key} exact path={item.path} component={lazyLoadComponent(item.component)} />;
        let subRoutes = null;
        if ( item.sub && item.sub.length > 0 ) {
          subRoutes = item.sub.map( (subItem: MenuItem, key) => {
            if (subItem.path && subItem.component) {
              return <Route key={key} exact path={item.path+subItem.path} component={lazyLoadComponent(subItem.component)} />;
            }
          } ) 
        }
        return route; //<React.Fragment>{route}{subRoutes}</React.Fragment>;*/
      //console.log('url',item.path);
      if (item.path === history.location.pathname)
        document.title = siteTitle + ": " + item.title;
      return (
        <Route
          key={key}
          exact
          path={item.path}
          // title={item.title}
          component={lazyLoadComponent(item.component)}
        />
      );
    } else {
      if (item.sub && item.sub.length > 0) {
        //console.log('path',item.path);
        const subRoutes = item.sub.map((subItem: MenuItem, subKey) => {
          //console.log('sub-path',[item.path, subItem.path].join('') );
          if ([item.path, subItem.path].join("") === history.location.pathname)
            document.title = siteTitle + ": " + subItem.title;
          if (subItem.path && subItem.component) {
            return (
              <Route
                key={subKey}
                exact
                path={item.path + subItem.path}
                // title={subItem.title}
                component={lazyLoadComponent(subItem.component)}
              />
            );
          }
        });
        return subRoutes;
      }
    }
  });

  return <React.Fragment>{menuRoutes}</React.Fragment>;
};

export const MainMenu: React.FC = () => {
  let history = useHistory();
  const role = useSelector((state: AppState) => state.user.info?.role);
  const status = useSelector((state: AppState) => state.user.status?.type);

  const { SubMenu } = Menu;

  function to(path?: string) {
    if (path) {
      history.push(path);
    } else {
      history.push("/");
    }
  }

  let menuItems: MenuItem[] = PageRoutes;

  if (role) {
    if (role == 100) {
      menuItems = PageRoutesAdmin;
    } else if (role == 10) {
      menuItems = PageRoutesUser;
    } else if (role == 1) {
      menuItems = PageRoutesSales;
    }
  }

  return (
    <div className="mb-8 grid md:grid-cols-2 md:grid-cols-3 gap-1 md:gap-4 z-10 relative">
      <div className="text-white flex justify-center md:justify-start">
        <a href="/">
          <img
            className="w-64 md:w-48 p-5 opacity-50 hover:opacity-100"
            src="/img/leadtrigger.svg"
          />
        </a>
      </div>
      <div className="md:col-span-2 md:pr-3">
        <Menu
          mode="horizontal"
          theme="dark"
          className="flex justify-center md:justify-end"
        >
          {menuItems.map((item: MenuItem, key: number) => {
            /*if ( item.id ) {
                const checker = serviceList.filter( ( service ) => service.id === item.id );
                if ( checker.length === 0 ) {
                    return false;
                }
            }*/
            //if (key !== undefined) return Item(item, key);
            //return <MainMenuItem item={item} key={key} />;

            let path = item.path;

            if (!item.hide && item.path !== "/") {
              let menuTitle: string | JSX.Element = item.title;
              if (item.icon) {
                menuTitle = (
                  <div className="text-center -mx-4">
                    {item.icon}
                    <div
                      className="-mt-8 pt-1 font-thin"
                      style={{ fontSize: "0.45rem" }}
                    >
                      {item.title}
                    </div>
                  </div>
                );
              }
              if (item.component && !item.sub) {
                return (
                  <Menu.Item onClick={() => to(path)} key={key}>
                    {menuTitle}
                  </Menu.Item>
                );
              } else if (item.sub) {
                return (
                  <SubMenu title={menuTitle} key={key}>
                    {item.sub.map((subitem: MenuItem, subKey: number) => {
                      if (!subitem.hide) {
                        return (
                          <Menu.Item
                            key={key + "-" + subKey}
                            onClick={() => to(path + subitem.path)}
                          >
                            {subitem.title}
                          </Menu.Item>
                        );
                      }
                    })}
                  </SubMenu>
                );
              }
            }
            return <React.Fragment key={key} />;
          })}
        </Menu>
      </div>
    </div>
  );
};
