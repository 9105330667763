import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchAccounts,
  fetchAccountsSucceeded,
  fetchAccount,
  fetchAccountSucceeded,
  saveAccount,
  saveAccountSucceeded,
  clearAccount,
  fetchSetting,
  fetchSettingSucceeded,
  fetchCampaigns,
  fetchCampaignsSucceeded,
  saveCampaign,
  saveCampaignSucceeded,
  saveAd,
  saveAdSucceeded,
  fetchPreview,
  fetchPreviewSucceeded,
  fetchAds,
  fetchAd,
  fetchAdPreview,
  setAdPreview,
  fetchAdsSucceeded,
  saveCampaignAdsetAd,
  saveCampaignAdsetAdSucceeded,
  fetchCampaignGroups,
  fetchCampaignGroupsSucceeded,
  IPut,
  fetchAudiences,
  setAudiences,
  fetchShare,
  fetchShareSucceeded,
  updateCampaign,
  updateCampaignSucceeded,
  updateCampaignStatus,
  updateCampaignStatusSucceeded,
  fetchAdTemplates,
  fetchAdTemplatesSucceeded,
  listCompanies,
  listCompaniesSucceeded,
} from "../reducers/linkedinSlice";
import { backend_address } from "../config/axios";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchAccounts(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/listAccounts", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchAccountsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAccount(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAccountSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveAccount(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/saveAccount", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveAccountSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchSetting(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/getSetting", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchSettingSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchCampaigns(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/getCampaigns",
        { campaign_group_id: data.payload.campaign_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchCampaignsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveCampaign(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/saveCampaign", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveCampaignSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveAd(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/saveAds", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveAdSucceeded(response));
    data.payload.id = response.id;
    yield runFetchPreview(data);
  } catch (e) {
    // handle errors
  }
}

function* runFetchPreview(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/getPreview",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchPreviewSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAds(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/listAds", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchAdsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAd(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/get",
        { creative_id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    //yield put(setAd(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAudiences(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/getCustomAudiances", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(setAudiences(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAdPreview(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/generatePreview", data.payload.preview, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(setAdPreview(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveCampaignAdsetAd(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/createNewCampaign", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveCampaignAdsetAdSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchCampaignGroups(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/getCampaignsGroups",
        { campaign_id: data.payload.campaign_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchCampaignGroupsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchShare(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/getShare",
        { share_id: data.payload.share_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchShareSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runUpdateCampaign(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/makeCampaignUpdate", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(updateCampaignSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runUpdateCampaignStatus(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/LinkedIn/updateCampaignGroupStatus", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(updateCampaignStatusSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchAdTemplates(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/listAdTemplates",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchAdTemplatesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runListCompanies(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/LinkedIn/showCompanies",
        { campaign_id: data.payload.campaign_id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(listCompaniesSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* linkedinSaga() {
  yield takeEvery(fetchAccounts.type, runFetchAccounts);
  yield takeEvery(fetchAccount.type, runFetchAccount);
  yield takeEvery(saveAccount.type, runSaveAccount);

  yield takeEvery(fetchCampaigns.type, runFetchCampaigns);
  yield takeEvery(saveCampaign.type, runSaveCampaign);

  yield takeEvery(fetchSetting.type, runFetchSetting);

  yield takeEvery(fetchAds.type, runFetchAds);
  yield takeEvery(fetchAd.type, runFetchAd);
  yield takeEvery(saveAd.type, runSaveAd);
  yield takeEvery(fetchPreview.type, runFetchPreview);
  yield takeEvery(fetchAdPreview.type, runFetchAdPreview);

  yield takeEvery(saveCampaignAdsetAd.type, runSaveCampaignAdsetAd);

  yield takeEvery(fetchCampaignGroups.type, runFetchCampaignGroups);

  yield takeEvery(fetchAudiences.type, runFetchAudiences);

  yield takeEvery(fetchShare.type, runFetchShare);
  yield takeEvery(updateCampaign.type, runUpdateCampaign);
  yield takeEvery(updateCampaignStatus.type, runUpdateCampaignStatus);

  yield takeEvery(fetchAdTemplates.type, runFetchAdTemplates);

  yield takeEvery(listCompanies.type, runListCompanies);
}

export default linkedinSaga;
