import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ICall {
  id: number;
  customer_id: number;
  user_id: number;
  client_id: number;
  customer: ICustomer;
  cold_id?: number;
}

interface IList {
  id: number;
  name: string;
  count?: number;
}

interface IB2BCompany {
  name: string;
  value: string;
}

interface ICustomer {
  id: number;
  client_id: number;
  user_id: number;
  created_at: number;
  modified_at: number;
  company: string;
  vatnumber: string;
  address: string;
  zipcode: string;
  city: string;
  phone: string;
  email: string;
  information: string;
  contact_name: string;
  contact_title: string;
  prospect: number;
  sha: string;
  linkedin: string;
  www: string;
  action?: number;
}

interface ISearch {
  id: number;
  customer_id: number;
  user_id: number;
  client_id: number;
  company: string;
  phone: string;
  contact_name: string;
  sha: string;
}

export interface IActions {
  id: number;
  customer_id: number;
  action_type_id: IType;
  information: string;
}

interface IType {
  id: number;
  name: string;
}

export interface LeadsState {
  call: ICall | undefined | "loading";
  actions: Array<IActions> | undefined | "loading";
  search: Array<ISearch> | undefined | "loading";
  leads: Array<ICustomer> | undefined | "loading";
  add: any;
  type: any;
  list_id: any;
  cold_list: Array<IList> | undefined | "loading";
  b2bcompanydata: Array<IB2BCompany> | undefined | "loading";
}

export interface IPut {
  token: string | undefined;
  id?: any;
  type?: any;
  list?: any;
  list_id?: any;
}

const initialState: LeadsState = {
  call: undefined,
  actions: undefined,
  search: undefined,
  leads: undefined,
  add: 0,
  type: 0,
  list_id: undefined,
  cold_list: undefined,
  b2bcompanydata: undefined,
};

const LeadsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    fetchCallWithId: (state: LeadsState, action: PayloadAction<IPut>) => {
      state.call = "loading";
    },
    fetchCallWithIdSucceeded: (
      state: LeadsState,
      action: PayloadAction<ICall | undefined | "loading">
    ) => {
      //state.search = undefined;
      state.actions = undefined;
      state.b2bcompanydata = undefined;
      state.call = action.payload;
    },
    fetchCallWithIdCold: (state: LeadsState, action: PayloadAction<IPut>) => {
      state.call = "loading";
    },
    fetchCallWithIdColdSucceeded: (
      state: LeadsState,
      action: PayloadAction<ICall>
    ) => {
      state.actions = undefined;
      state.b2bcompanydata = undefined;
      state.call = action.payload;
    },
    clearCall: (state: LeadsState, action: PayloadAction<any>) => {
      state.actions = undefined;
      state.b2bcompanydata = undefined;
      state.call = undefined;
    },

    fetchCall: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchCallSucceeded: (
      state: LeadsState,
      action: PayloadAction<ICall | undefined>
    ) => {
      //state.search = undefined;
      state.actions = undefined;
      state.b2bcompanydata = undefined;
      state.call = action.payload;
    },
    fetchActions: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchActionsSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<IActions>>
    ) => {
      state.actions = action.payload;
    },
    fetchSearch: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchSearchSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<ISearch> | undefined | "loading">
    ) => {
      state.search = action.payload;
    },
    fetchSearchCold: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchSearchColdSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<ISearch> | undefined | "loading">
    ) => {
      state.search = action.payload;
    },

    makeCall: (state: LeadsState, action: PayloadAction<IPut>) => {},
    makeCallSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<ICall>>
    ) => {},
    fetchCallNew: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchCallNewSucceeded: (
      state: LeadsState,
      action: PayloadAction<ICall>
    ) => {
      //state.search = undefined;
      state.actions = undefined;
      state.b2bcompanydata = undefined;
      state.call = action.payload;
    },
    addAction: (state: LeadsState, action: PayloadAction<IPut>) => {},
    addActionSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<ISearch>>
    ) => {
      state.call = undefined;
      state.b2bcompanydata = undefined;
      state.actions = undefined;
    },
    fetchNewLeads: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchNewLeadsSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<ICustomer>>
    ) => {
      state.leads = action.payload;
    },
    convertToCustomer: (state: LeadsState, action: PayloadAction<IPut>) => {},
    convertToCustomerSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<ISearch>>
    ) => {
      state.call = undefined;
      state.actions = undefined;
    },
    makeLeadShare: (state: LeadsState, action: PayloadAction<any>) => {},
    makeLeadShareSucceeded: (state: LeadsState, action: PayloadAction<any>) => {
      //state.leads = undefined;
    },
    addNewLead: (state: LeadsState, action: PayloadAction<any>) => {
      state.add = action.payload;
    },
    changeListType: (state: LeadsState, action: PayloadAction<any>) => {
      console.log("Setting listType to " + action.payload);
      state.type = action.payload;
    },
    setListId: (state: LeadsState, action: PayloadAction<any>) => {
      state.list_id = action.payload;
    },
    fetchColdList: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchColdListSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<IList>>
    ) => {
      state.cold_list = action.payload;
    },
    fetchB2BCompanyData: (state: LeadsState, action: PayloadAction<IPut>) => {},
    fetchB2BCompanyDataSucceeded: (
      state: LeadsState,
      action: PayloadAction<Array<IB2BCompany>>
    ) => {
      state.b2bcompanydata = action.payload;
    },
  },
});

export const {
  fetchCall,
  fetchCallSucceeded,
  fetchActions,
  fetchActionsSucceeded,
  fetchSearch,
  fetchSearchSucceeded,
  fetchCallWithId,
  fetchCallWithIdSucceeded,
  makeCall,
  makeCallSucceeded,
  addAction,
  addActionSucceeded,
  fetchNewLeads,
  fetchNewLeadsSucceeded,
  convertToCustomer,
  convertToCustomerSucceeded,
  makeLeadShare,
  makeLeadShareSucceeded,
  addNewLead,
  fetchCallNew,
  fetchCallNewSucceeded,
  changeListType,
  setListId,
  fetchColdList,
  fetchColdListSucceeded,
  fetchB2BCompanyData,
  fetchB2BCompanyDataSucceeded,
  fetchSearchCold,
  fetchSearchColdSucceeded,
  fetchCallWithIdCold,
  fetchCallWithIdColdSucceeded,
  clearCall,
} = LeadsSlice.actions;

export default LeadsSlice;
