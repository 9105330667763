import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchCall,
  fetchCallSucceeded,
  fetchActions,
  fetchActionsSucceeded,
  fetchSearch,
  fetchSearchSucceeded,
  fetchCallWithId,
  fetchCallWithIdSucceeded,
  makeCall,
  makeCallSucceeded,
  addAction,
  addActionSucceeded,
  fetchNewLeads,
  fetchNewLeadsSucceeded,
  convertToCustomer,
  convertToCustomerSucceeded,
  makeLeadShare,
  makeLeadShareSucceeded,
  fetchCallNew,
  fetchCallNewSucceeded,
  fetchColdList,
  fetchColdListSucceeded,
  fetchB2BCompanyData,
  fetchB2BCompanyDataSucceeded,
  fetchSearchCold,
  fetchSearchColdSucceeded,
  fetchCallWithIdCold,
  fetchCallWithIdColdSucceeded,
  IPut,
} from "../reducers/leadsSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchCallWithId(data: any) {
  yield put(fetchCallWithIdSucceeded("loading"));
  try {
    const response = yield call(() =>
      backend.post(
        "/call/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchCallWithIdSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchCallWithIdCold(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/call/getCold",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchCallWithIdColdSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchCall(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/call/get", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchCallSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchCallNew(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/call/getNew", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchCallNewSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchActions(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/call/actions",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchActionsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchSearch(data: any) {
  yield put(fetchSearchSucceeded("loading"));
  try {
    const response = yield call(() =>
      backend.post(
        "/call/search",
        { searchtext: data.payload.id, searchstatus: data.payload.type },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchSearchSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchSearchCold(data: any) {
  yield put(fetchSearchColdSucceeded("loading"));
  try {
    const response = yield call(() =>
      backend.post(
        "/call/searchCold",
        {
          searchtext: data.payload.id,
          searchstatus: data.payload.type,
          list_id: data.payload.list_id,
        },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchSearchColdSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runMakeCall(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/call/makecall",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(makeCallSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runAddAction(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/call/addAction",
        { id: data.payload.id, type: data.payload.type },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    // yield put(addActionSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchNewLeads(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Leads/listNewLeads", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchNewLeadsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runConvertToCustomer(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/call/convertToCustomer",
        { id: data.payload.id, type: data.payload.type },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(convertToCustomerSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runMakeLeadShare(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/Leads/makeLeadShare", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(makeLeadShareSucceeded(response));
    yield runFetchNewLeads(data);
  } catch (e) {
    // handle errors
  }
}

function* runFetchColdList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/Leads/getColdList",
        { status_id: 1 },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchColdListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BCompanyData(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/B2B/getCompanyData",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchB2BCompanyDataSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* leadsSaga() {
  yield takeEvery(fetchCall.type, runFetchCall);
  yield takeEvery(fetchCallNew.type, runFetchCallNew);
  yield takeEvery(fetchActions.type, runFetchActions);
  yield takeEvery(fetchSearch.type, runFetchSearch);
  yield takeEvery(fetchCallWithId.type, runFetchCallWithId);
  yield takeEvery(makeCall.type, runMakeCall);
  yield takeEvery(addAction.type, runAddAction);
  yield takeEvery(fetchNewLeads.type, runFetchNewLeads);
  yield takeEvery(convertToCustomer.type, runConvertToCustomer);
  yield takeEvery(makeLeadShare.type, runMakeLeadShare);
  yield takeEvery(fetchColdList.type, runFetchColdList);
  yield takeEvery(fetchB2BCompanyData.type, runFetchB2BCompanyData);
  yield takeEvery(fetchSearchCold.type, runFetchSearchCold);
  yield takeEvery(fetchCallWithIdCold.type, runFetchCallWithIdCold);
}

export default leadsSaga;
