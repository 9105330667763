import { all, takeEvery } from "redux-saga/effects";
import { PayloadActionWithResolve } from "../reducers/rootReducer";
import tasksSaga from "./tasksSaga";
import userSaga from "./userSaga";
import clientsSaga from "./clientsSaga";
import b2bSaga from "./b2bSaga";
import contactsSaga from "./contactsSaga";
import marketingSaga from "./marketingSaga";
import customersSaga from "./customersSaga";
import leadsSaga from "./leadsSaga";
import filesSaga from "./filesSaga";
import publicSaga from "./publicSaga";
import facebookSaga from "./facebookSaga";
import linkedinSaga from "./linkedinSaga";

export default function* rootSaga() {
  // Attach an "onResolve" method on any
  // payload dispatched by a Saga so it can be
  // called here and you get feedback from within your component
  yield takeEvery(
    "*",
    (
      action: PayloadActionWithResolve<any> & {
        "@@redux-saga/SAGA_ACTION": boolean;
      }
    ) => {
      if (action.onResolve && action["@@redux-saga/SAGA_ACTION"]) {
        action.onResolve(action.payload);
      }
    }
  );
  yield all([
    userSaga(),
    tasksSaga(),
    clientsSaga(),
    b2bSaga(),
    contactsSaga(),
    marketingSaga(),
    customersSaga(),
    leadsSaga(),
    filesSaga(),
    publicSaga(),
    facebookSaga(),
    linkedinSaga(),
  ]);
}
