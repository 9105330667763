import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";

import { fetchImages, setImages } from "../reducers/filesSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchImages(data: any) {
  yield put(setImages("loading"));
  try {
    const response = yield call(() =>
      backend.post(
        "/clientImages/list",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(setImages(response));
  } catch (e) {
    // handle errors
    yield put(setImages(undefined));
  }
}

function* filesSaga() {
  yield takeEvery(fetchImages.type, runFetchImages);
}

export default filesSaga;
