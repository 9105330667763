import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

export interface IB2B {
  id: number;
  client_id: number;
  user_id: number;
  name: string;
  created_at: number;
  modified_at: number;
  param: string;
  count: number;
  ctype: number;
  companytype?: number[] | string[] | null;
  turnover?: number[] | string[] | null;
  rating?: number[] | string[] | null;
  person?: number[] | string[] | null;
  titleclass?: number[] | string[] | null;
  title?: number[] | string[] | null;
  province?: number[] | string[] | null;
  municipality?: number[] | string[] | null;
  tol?: string[] | null; //number[] |
  tol5?: number[] | string[] | null;
  search_text: string;
  used: number;
  max: number;
  not_vat: string;
}

interface IPreview {
  id: number;
  toimipaikan_nimi: string;
  ytunnus: string;
  kunta: string;
  etunimi: string;
  sukunimi: string;
  titteli: string;
  luottotieto: string;
}

interface IB2BContact {
  id: number;
  company: string;
  vatnumber: string;
  address: string;
  city: string;
  name: string;
  title: string;
  marketing_list_id: number;
}

export interface B2BCompanyType {
  id: number;
  value: string;
  name: string;
}

export interface B2BTurnover {
  id: number;
  value: string;
  name: string;
}

export interface B2BRating {
  id: number;
  value: string;
  name: string;
}

export interface B2BProvince {
  id: number;
  value: string;
  name: string;
}

export interface B2BTitleClass {
  id: number;
  value: string;
  name: string;
}

export interface B2BTitle {
  id: number;
  value: string;
  name: string;
}

export interface B2BPerson {
  id: number;
  value: string;
  name: string;
}

export interface B2BMunicipality {
  id: number;
  value: string;
  name: string;
}

export interface B2BTol {
  id: number;
  code: string;
  name: string;
}

export interface Excel {
  id: number;
  name: string;
  created_at: number;
}

export interface B2BState {
  lists: Array<IB2B> | undefined;
  list: IB2B | undefined;
  companytype: Array<B2BCompanyType> | undefined;
  turnover: Array<B2BTurnover> | undefined;
  rating: Array<B2BRating> | undefined;
  province: Array<B2BProvince> | undefined;
  titleclass: Array<B2BTitleClass> | undefined;
  title: Array<B2BTitle> | undefined;
  person: Array<B2BPerson> | undefined;
  municipality: Array<B2BMunicipality> | undefined;
  tol: Array<B2BTol> | undefined;
  tol5: Array<B2BTol> | undefined;
  preview: Array<IPreview> | undefined;
  contacts: Array<any> | undefined;
  contact_count: number;
  excel: Array<Excel> | undefined;
  excelinfo: IB2B | undefined;
  b2bsearch: any;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  list_id?: number;
  offset?: number;
  limit?: number;
  only_new?: boolean;
}

const initialState: B2BState = {
  lists: undefined,
  list: undefined,
  companytype: undefined,
  turnover: undefined,
  rating: undefined,
  province: undefined,
  titleclass: undefined,
  title: undefined,
  person: undefined,
  municipality: undefined,
  tol: undefined,
  tol5: undefined,
  preview: undefined,
  contacts: undefined,
  contact_count: 0,
  excel: undefined,
  excelinfo: undefined,
  b2bsearch: undefined,
};

const msg = message;

const b2bSlice = createSlice({
  name: "b2b",
  initialState,
  reducers: {
    fetchB2BCompanytype: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BCompanytypeSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BCompanyType>>
    ) => {
      state.companytype = action.payload;
    },
    fetchB2BTurnover: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BTurnoverSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BCompanyType>>
    ) => {
      state.turnover = action.payload;
    },
    fetchB2BRating: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BRatingSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BRating>>
    ) => {
      state.rating = action.payload;
    },
    fetchB2BTol: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BTolSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BTol>>
    ) => {
      state.tol = action.payload;
    },
    fetchB2BTol5: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BTol5Succeeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BTol>>
    ) => {
      state.tol5 = action.payload;
    },
    fetchB2BProvince: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BProvinceSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BProvince>>
    ) => {
      state.province = action.payload;
    },
    fetchB2BTitleClass: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BTitleClassSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BTitleClass>>
    ) => {
      state.titleclass = action.payload;
    },
    fetchB2BTitle: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BTitleSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BTitle>>
    ) => {
      state.title = action.payload;
    },
    fetchB2BPerson: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BPersonSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BPerson>>
    ) => {
      state.person = action.payload;
    },
    fetchB2BMunicipality: (
      state: B2BState,
      action: PayloadAction<string | undefined>
    ) => {},
    fetchB2BMunicipalitySucceeded: (
      state: B2BState,
      action: PayloadAction<Array<B2BMunicipality>>
    ) => {
      state.municipality = action.payload;
    },

    fetchExcelInfo: (state: B2BState, action: PayloadAction<IPut>) => {},
    fetchExcelInfoSucceeded: (state: B2BState, action: PayloadAction<IB2B>) => {
      state.excelinfo = action.payload;
    },
    fetchB2BLists: (state: B2BState, action: PayloadAction<IPut>) => {},
    fetchB2BListsSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<IB2B>>
    ) => {
      state.preview = undefined;
      state.lists = action.payload;
    },

    fetchVatNumberSearch: (state: B2BState, action: PayloadAction<any>) => {},
    fetchVatNumberSearchSucceeded: (
      state: B2BState,
      action: PayloadAction<IB2B>
    ) => {
      state.list = action.payload;
    },

    fetchB2BList: (state: B2BState, action: PayloadAction<IPut>) => {},
    fetchB2BListSucceeded: (state: B2BState, action: PayloadAction<IB2B>) => {
      state.list = action.payload;
    },
    saveB2BList: (state: B2BState, action: PayloadAction<IB2B>) => {},
    saveB2BListSucceeded: (state: B2BState, action: PayloadAction<IB2B>) => {
      state.list = undefined;
    },
    clearB2BList: (state: B2BState) => {
      state.list = undefined;
    },
    fetchB2Bpreview2: (state: B2BState, action: PayloadAction<any>) => {
      state.preview = action.payload;
    },
    fetchB2Bpreview: (state: B2BState, action: PayloadAction<IPut>) => {},
    fetchB2BpreviewSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<IPreview>>
    ) => {
      state.preview = action.payload;
    },
    removeB2BList: (state: B2BState, action: PayloadAction<IPut>) => {},
    removeB2BListSucceeded: (state: B2BState, action: PayloadAction<any>) => {
      state.list = undefined;
      state.preview = undefined;
    },
    copyB2BList: (state: B2BState, action: PayloadAction<IPut>) => {},
    copyB2BListSucceeded: (state: B2BState, action: PayloadAction<any>) => {
      state.list = undefined;
      state.preview = undefined;
    },

    fetchContactLists: (state: B2BState, action: PayloadAction<IPut>) => {},
    fetchContactListsSucceeded: (
      state: B2BState,
      action: PayloadAction<any>
    ) => {
      state.contact_count = action.payload.contact_count;
      state.contacts = action.payload.contacts;
    },
    removeContact: (state: B2BState, action: PayloadAction<IPut>) => {},
    removeContactSucceeded: (state: B2BState, action: PayloadAction<any>) => {
      const contacts = state.contacts;
      if (contacts) {
        const newList = contacts.filter(
          (item) => item.id !== action.payload.id
        );
        if (newList) {
          state.contacts = newList;
        }
      }
    },
    fetchExcel: (state: B2BState, action: PayloadAction<IPut>) => {},
    fetchExcelSucceeded: (
      state: B2BState,
      action: PayloadAction<Array<Excel>>
    ) => {
      state.excel = action.payload;
    },
    saveExcel: (state: B2BState, action: PayloadAction<IPut>) => {}, //<IB2B>
    saveExcelSucceeded: (state: B2BState, action: PayloadAction<IB2B>) => {
      state.excelinfo = undefined;
      state.excel = undefined;
      msg.loading(
        "Excelin muodostus käynnissä. Excel tulee ladattavaksi muutaman minuutin kuluttua.",
        10
      );
    },
    fetchSearchB2BRegistry: (
      state: B2BState,
      action: PayloadAction<IPut>
    ) => {},
    fetchSearchB2BRegistrySucceeded: (
      state: B2BState,
      action: PayloadAction<Array<Excel>>
    ) => {
      state.b2bsearch = action.payload;
    },
  },
});

export const {
  fetchB2BLists,
  fetchB2BListsSucceeded,
  fetchB2BList,
  fetchB2BListSucceeded,
  saveB2BList,
  saveB2BListSucceeded,
  clearB2BList,
  fetchB2BCompanytype,
  fetchB2BCompanytypeSucceeded,
  fetchB2BTurnover,
  fetchB2BTurnoverSucceeded,
  fetchB2BRating,
  fetchB2BRatingSucceeded,
  fetchB2BProvince,
  fetchB2BProvinceSucceeded,
  fetchB2BTitleClass,
  fetchB2BTitleClassSucceeded,
  fetchB2BTitle,
  fetchB2BTitleSucceeded,
  fetchB2BPerson,
  fetchB2BPersonSucceeded,
  fetchB2BMunicipality,
  fetchB2BMunicipalitySucceeded,
  fetchB2Bpreview,
  fetchB2BpreviewSucceeded,
  fetchB2BTol,
  fetchB2BTolSucceeded,
  fetchB2BTol5,
  fetchB2BTol5Succeeded,
  fetchContactLists,
  fetchContactListsSucceeded,
  removeContact,
  removeContactSucceeded,
  fetchB2Bpreview2,
  removeB2BList,
  removeB2BListSucceeded,
  copyB2BList,
  copyB2BListSucceeded,
  fetchExcel,
  fetchExcelSucceeded,
  fetchExcelInfo,
  fetchExcelInfoSucceeded,
  saveExcel,
  saveExcelSucceeded,
  fetchVatNumberSearch,
  fetchVatNumberSearchSucceeded,

  fetchSearchB2BRegistry,
  fetchSearchB2BRegistrySucceeded,
} = b2bSlice.actions;

export default b2bSlice;
