import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IList {
  id: number;
  name: string;
  created_at: number;
  modified_at: number;
  status_id: number;
  client_id: number;
  user_id: number;
  filename: string;
  own_list: string;
  adding_type: number;
  count: number;
  date_count: number;
  contacts: any;
  client_astieto_list_id: number;
  key: number;
}

export interface ContactsState {
  lists: Array<IList> | undefined;
  list: IList | undefined;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  status_id?: number;
  limit_date?: number;
}

const initialState: ContactsState = {
  lists: undefined,
  list: undefined,
};

const ContactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    fetchLists: (state: ContactsState, action: PayloadAction<IPut>) => {},
    fetchListsSucceeded: (
      state: ContactsState,
      action: PayloadAction<Array<IList>>
    ) => {
      state.lists = action.payload;
    },
    clearLists: (state: ContactsState) => {
      state.lists = undefined;
    },
    fetchListsByDate: (state: ContactsState, action: PayloadAction<IPut>) => {},
    fetchListsByDateSucceeded: (
      state: ContactsState,
      action: PayloadAction<Array<IList>>
    ) => {
      state.lists = action.payload;
    },

    fetchList: (state: ContactsState, action: PayloadAction<IPut>) => {},
    clearList: (state: ContactsState) => {
      state.list = undefined;
    },
    fetchListSucceeded: (
      state: ContactsState,
      action: PayloadAction<IList>
    ) => {
      state.list = action.payload;
    },
    saveList: (state: ContactsState, action: PayloadAction<IList>) => {},
    saveListSucceeded: (state: ContactsState, action: PayloadAction<IList>) => {
      state.list = action.payload;
    },
  },
});

export const {
  fetchLists,
  fetchListsSucceeded,
  fetchList,
  clearList,
  fetchListSucceeded,
  saveList,
  saveListSucceeded,
  clearLists,
  fetchListsByDate,
  fetchListsByDateSucceeded,
} = ContactsSlice.actions;

export default ContactsSlice;
