import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchLists,
  fetchListsSucceeded,
  fetchList,
  fetchListSucceeded,
  saveList,
  saveListSucceeded,
  fetchListsByDate,
  fetchListsByDateSucceeded,
  IPut,
} from "../reducers/contactsSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchLists(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingLists/list", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchListsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchListsByDate(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingLists/listByDate", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    console.log(response);
    yield put(fetchListsByDateSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingLists/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveList(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/MarketingLists/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield runFetchLists(data);
    yield put(saveListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* contactsSaga() {
  yield takeEvery(fetchListsByDate.type, runFetchListsByDate);
  yield takeEvery(fetchLists.type, runFetchLists);
  yield takeEvery(fetchList.type, runFetchList);
  yield takeEvery(saveList.type, runSaveList);
}

export default contactsSaga;
