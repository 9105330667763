import { takeEvery, call, put } from "redux-saga/effects";
import backend from "../config/axios";
import {
  fetchB2BLists,
  fetchB2BListsSucceeded,
  fetchB2BList,
  fetchB2BListSucceeded,
  saveB2BList,
  saveB2BListSucceeded,
  fetchB2BCompanytype,
  fetchB2BCompanytypeSucceeded,
  fetchB2BTurnover,
  fetchB2BTurnoverSucceeded,
  fetchB2BRating,
  fetchB2BRatingSucceeded,
  fetchB2BProvince,
  fetchB2BProvinceSucceeded,
  fetchB2BTitleClass,
  fetchB2BTitleClassSucceeded,
  fetchB2BTitle,
  fetchB2BTitleSucceeded,
  fetchB2BPerson,
  fetchB2BPersonSucceeded,
  fetchB2BMunicipality,
  fetchB2BMunicipalitySucceeded,
  fetchB2Bpreview,
  fetchB2BpreviewSucceeded,
  fetchB2BTol,
  fetchB2BTolSucceeded,
  fetchB2BTol5,
  fetchB2BTol5Succeeded,
  fetchContactLists,
  fetchContactListsSucceeded,
  removeContact,
  removeContactSucceeded,
  removeB2BList,
  removeB2BListSucceeded,
  copyB2BList,
  copyB2BListSucceeded,
  fetchExcel,
  fetchExcelSucceeded,
  fetchExcelInfo,
  fetchExcelInfoSucceeded,
  saveExcel,
  saveExcelSucceeded,
  fetchVatNumberSearch,
  fetchVatNumberSearchSucceeded,
  fetchSearchB2BRegistry,
  fetchSearchB2BRegistrySucceeded,
  IPut,
} from "../reducers/b2bSlice";

import {
  fetchLists,
  fetchListsSucceeded,
  clearLists,
} from "../reducers/contactsSlice";

import { AppState } from "../reducers/rootReducer";
import { worker } from "cluster";

function* runFetchExcelInfo(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/ClientExcelLists/getExcel",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchExcelInfoSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveExcel(data: any) {
  yield put(clearLists());
  const sendData = {
    id: data.payload.id,
    filter: data.payload.filter,
    contact_count: data.payload.limit,
  };

  try {
    const response = yield call(() =>
      backend.post("/B2B/makeReportExcel", sendData, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(saveExcelSucceeded(response));
    yield fetchExcelInfo({ token: data.payload.token });
    yield fetchExcel({ token: data.payload.token });
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BLists(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/list", null, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );

    yield put(fetchB2BListsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2Bpreview(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/B2B/showContacts",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchB2BpreviewSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchVatNumberSearch(data: any) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/vatnumberSearch", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    yield put(fetchVatNumberSearchSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/B2B/get",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchB2BListSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runSaveB2BList(data: any) {
  yield put(clearLists());
  try {
    const response = yield call(() =>
      backend.post("/B2B/add", data.payload, {
        headers: {
          Authorization: `Bearer ${data.payload.token}`,
        },
      })
    );
    console.log(data.payload);
    yield put(saveB2BListSucceeded(response));
    //yield runFetchB2BLists(data);
    yield put(fetchLists(data.payload));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BCompanytype(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/companytype", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BCompanytypeSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BTurnover(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/turnover", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BTurnoverSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BRating(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/rating", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BRatingSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BTol(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/tol", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BTolSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BTol5(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/tol5", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BTol5Succeeded(response));
  } catch (e) {
    // handle errors
  }
}
function* runFetchB2BProvince(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/province", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BProvinceSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BTitleClass(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/titleclass", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BTitleClassSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BTitle(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/title", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BTitleSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BPerson(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/person", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BPersonSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchB2BMunicipality(data: any | undefined) {
  try {
    const response = yield call(() =>
      backend.post("/B2B/municipality", null, {
        headers: {
          Authorization: `Bearer ${data.payload}`,
        },
      })
    );
    yield put(fetchB2BMunicipalitySucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchContactLists(data: any) {
  try {
    const onlyNew: number = data.payload.only_new === true ? 1 : 0;
    const response = yield call(() =>
      //no_already_exported: only_new: onlyNew,
      backend.post(
        "/B2B/showContactsList",
        {
          id: data.payload.id,
          offset: data.payload.offset,
          limit: data.payload.limit,
          no_already_exported: onlyNew,
        },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchContactListsSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runRemoveContact(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/B2B/removeContactsList",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(removeContactSucceeded(response));
    data.payload.id = data.payload.list_id;
    //yield runFetchContactLists(data);
  } catch (e) {}
}

function* runRemoveB2BList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/B2B/delete",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(removeB2BListSucceeded(response));
  } catch (e) {}
}

function* runCopyB2BList(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/MarketingLists/makeCopy",
        { id: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(copyB2BListSucceeded(response));
  } catch (e) {}
}

function* runFetchExcel(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/ClientExcelLists/list",
        {},
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    yield put(fetchExcelSucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* runFetchSearchB2BRegistry(data: any) {
  try {
    const response = yield call(() =>
      backend.post(
        "/B2B/companySearch",
        { searchText: data.payload.id },
        {
          headers: {
            Authorization: `Bearer ${data.payload.token}`,
          },
        }
      )
    );
    console.log(response);
    yield put(fetchSearchB2BRegistrySucceeded(response));
  } catch (e) {
    // handle errors
  }
}

function* b2bSaga() {
  yield takeEvery(saveExcel.type, runSaveExcel);
  yield takeEvery(fetchExcelInfo.type, runFetchExcelInfo);
  yield takeEvery(fetchB2BLists.type, runFetchB2BLists);
  yield takeEvery(fetchB2BList.type, runFetchB2BList);
  yield takeEvery(saveB2BList.type, runSaveB2BList);
  yield takeEvery(fetchB2BCompanytype.type, runFetchB2BCompanytype);
  yield takeEvery(fetchB2BTurnover.type, runFetchB2BTurnover);
  yield takeEvery(fetchB2BRating.type, runFetchB2BRating);
  yield takeEvery(fetchB2BTol.type, runFetchB2BTol);
  yield takeEvery(fetchB2BTol5.type, runFetchB2BTol5);
  yield takeEvery(fetchB2BProvince.type, runFetchB2BProvince);
  yield takeEvery(fetchB2BTitleClass.type, runFetchB2BTitleClass);
  yield takeEvery(fetchB2BTitle.type, runFetchB2BTitle);
  yield takeEvery(fetchB2BPerson.type, runFetchB2BPerson);
  yield takeEvery(fetchB2BMunicipality.type, runFetchB2BMunicipality);
  yield takeEvery(fetchB2Bpreview.type, runFetchB2Bpreview);
  yield takeEvery(fetchContactLists.type, runFetchContactLists);
  yield takeEvery(removeContact.type, runRemoveContact);
  yield takeEvery(removeB2BList.type, runRemoveB2BList);
  yield takeEvery(copyB2BList.type, runCopyB2BList);
  yield takeEvery(fetchExcel.type, runFetchExcel);
  yield takeEvery(fetchVatNumberSearch.type, runFetchVatNumberSearch);
  yield takeEvery(fetchSearchB2BRegistry.type, runFetchSearchB2BRegistry);
}

export default b2bSaga;
