import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAccount {
  id: string;
  name: string;
}
export interface IPage {
  id: string;
  name: string;
}

export interface ICampaign {
  id: string;
  name: string;
  status: string;
}

export interface IAd {
  id: string;
  creative_id: string;
  name: string;
  object_story_spec: any;
  preview: any;
}

export interface ISetting {
  id: string;
  adaccount_id: string;
  page_id: string;
}

export interface FacebookState {
  accounts: Array<IAccount> | undefined;
  account: IAccount | undefined;
  setting: ISetting | undefined;
  pages: Array<IPage> | undefined;
  page: IPage | undefined;
  campaigns: Array<ICampaign> | undefined;
  campaign: any;
  ad: IAd | undefined;
  ads: any;
  adset: any;
  preview: any;
  adsets: any;
  audiences: any;
  adtemplates: any;
  personparams: any;
  locations: any;
  somereports: any;
}

export interface IPut {
  token: string | undefined;
  id?: number;
  type?: number;
  campaign_id?: string;
  starttime?: number;
  endtime?: number;
  status?: string;
  search_text?: string;
}

const initialState: FacebookState = {
  accounts: undefined,
  account: undefined,
  setting: undefined,
  pages: undefined,
  page: undefined,
  campaigns: undefined,
  campaign: undefined,
  ad: undefined,
  ads: undefined,
  adset: undefined,
  adsets: undefined,
  preview: undefined,
  audiences: undefined,
  adtemplates: undefined,
  personparams: undefined,
  locations: undefined,
  somereports: undefined,
};

const facebookSlice = createSlice({
  name: "facebook",
  initialState,
  reducers: {
    fetchAccounts: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchAccountsSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<IAccount>>
    ) => {
      state.accounts = action.payload;
    },
    fetchAccount: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchAccountSucceeded: (
      state: FacebookState,
      action: PayloadAction<IAccount>
    ) => {
      state.account = action.payload;
    },
    saveAccount: (state: FacebookState, action: PayloadAction<any>) => {},
    saveAccountSucceeded: (
      state: FacebookState,
      action: PayloadAction<IAccount>
    ) => {
      state.account = undefined;
    },
    clearAccount: (state: FacebookState) => {
      state.account = undefined;
    },

    fetchSetting: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchSettingSucceeded: (
      state: FacebookState,
      action: PayloadAction<ISetting>
    ) => {
      state.setting = action.payload;
    },

    fetchPages: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchPagesSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<IAccount>>
    ) => {
      state.pages = action.payload;
    },
    savePage: (state: FacebookState, action: PayloadAction<any>) => {},
    savePageSucceeded: (
      state: FacebookState,
      action: PayloadAction<ICampaign>
    ) => {
      state.page = undefined;
    },
    fetchCampaigns: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchCampaignsSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<ICampaign>>
    ) => {
      state.campaigns = action.payload;
    },
    saveCampaign: (state: FacebookState, action: PayloadAction<any>) => {},
    saveCampaignSucceeded: (
      state: FacebookState,
      action: PayloadAction<ICampaign>
    ) => {
      state.campaign = action.payload;
    },

    saveAd: (state: FacebookState, action: PayloadAction<any>) => {},
    saveAdSucceeded: (state: FacebookState, action: PayloadAction<any>) => {
      state.ad = action.payload;
    },
    setAd: (state: FacebookState, action: PayloadAction<IAd | undefined>) => {
      state.ad = action.payload;
    },
    fetchAdPreview: (state: FacebookState, action: PayloadAction<any>) => {},
    setAdPreview: (state: FacebookState, action: PayloadAction<any>) => {
      if (state.ad) state.ad.preview = action.payload;
    },
    fetchPreview: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchPreviewSucceeded: (
      state: FacebookState,
      action: PayloadAction<any>
    ) => {
      state.preview = action.payload;
    },
    fetchAd: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchAds: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchAdsSucceeded: (state: FacebookState, action: PayloadAction<any>) => {
      state.ads = action.payload;
    },

    saveCampaignAdsetAd: (
      state: FacebookState,
      action: PayloadAction<any>
    ) => {},
    saveCampaignAdsetAdSucceeded: (
      state: FacebookState,
      action: PayloadAction<any>
    ) => {
      state.campaign = action.payload.campaign_id;
      state.adset = action.payload.adset_id;
      state.ad = action.payload.ad_id;
      window.location.href = "/some/facebook";
    },
    fetchAdsets: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchAdsetsSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<IAccount>>
    ) => {
      state.adsets = action.payload;
    },
    fetchAudiences: (state: FacebookState, action: PayloadAction<IPut>) => {},
    setAudiences: (state: FacebookState, action: PayloadAction<any>) => {
      state.audiences = action.payload;
    },

    updateCampaignStatus: (
      state: FacebookState,
      action: PayloadAction<IPut>
    ) => {},
    updateCampaignStatusSucceeded: (
      state: FacebookState,
      action: PayloadAction<any>
    ) => {},

    updateCampaign: (state: FacebookState, action: PayloadAction<any>) => {},
    updateCampaignSucceeded: (
      state: FacebookState,
      action: PayloadAction<any>
    ) => {},
    fetchAdTemplates: (state: FacebookState, action: PayloadAction<IPut>) => {},
    fetchAdTemplatesSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<any>>
    ) => {
      state.adtemplates = action.payload;
    },

    listPersonParams: (state: FacebookState, action: PayloadAction<IPut>) => {},
    listPersonParamsSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<any>>
    ) => {
      state.personparams = action.payload;
    },
    searchLocations: (state: FacebookState, action: PayloadAction<IPut>) => {},
    searchLocationsSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<any>>
    ) => {
      state.locations = action.payload;
    },
    getSomeReports: (state: FacebookState, action: PayloadAction<IPut>) => {
      state.somereports = undefined;
    },
    getSomeReportsSucceeded: (
      state: FacebookState,
      action: PayloadAction<Array<any>>
    ) => {
      state.somereports = action.payload;
    },
  },
});

export const {
  fetchAccounts,
  fetchAccountsSucceeded,
  fetchAccount,
  fetchAccountSucceeded,
  saveAccount,
  saveAccountSucceeded,
  clearAccount,
  fetchSetting,
  fetchSettingSucceeded,
  fetchPages,
  fetchPagesSucceeded,
  savePage,
  savePageSucceeded,
  fetchCampaigns,
  fetchCampaignsSucceeded,
  saveCampaign,
  saveCampaignSucceeded,
  saveAd,
  saveAdSucceeded,
  fetchPreview,
  fetchPreviewSucceeded,
  fetchAds,
  fetchAd,
  fetchAdPreview,
  setAd,
  setAdPreview,
  fetchAdsSucceeded,
  saveCampaignAdsetAd,
  saveCampaignAdsetAdSucceeded,
  fetchAdsets,
  fetchAdsetsSucceeded,
  fetchAudiences,
  setAudiences,
  updateCampaignStatus,
  updateCampaignStatusSucceeded,
  updateCampaign,
  updateCampaignSucceeded,
  fetchAdTemplates,
  fetchAdTemplatesSucceeded,
  listPersonParams,
  listPersonParamsSucceeded,
  searchLocations,
  searchLocationsSucceeded,
  getSomeReports,
  getSomeReportsSucceeded,
} = facebookSlice.actions;

export default facebookSlice;
